import React from 'react';
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQItem = ({ question, answer }) => {
  return (
    <Card style={{ marginBottom: '15px' }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{ fontWeight: 'bold' }}>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

const FAQPage = () => {
  // Sample data, you can replace this with your FAQ data
  const faqs = [
    {
      question: "How are the images generated?",
      answer: "PodPipe uses the Midjourney AI Pro version. Other Image AI tools might be supported in the near future."
    },
    {
      question: "Do I need my own Midjourney Subscription?",
      answer: "During the free Demo you don't, afterwards yes. You need your own Midjourney Pro subscription with enough fast hours, the Midjourney relaxed mode is not supported currently. A subscription based model where PodPipe also manages the Midjourney subscription for you is in the making! If you need it now, please contact :)"
    },
    {
        question: "How are the Listings created?",
        answer: "PodPipe leverages ChatGPT to create the title, tags and description automagically. The quality is ever increasing and in most cases ready for your store without any intervention. You can easily edit the Listing however."
    },
    {
        question: "Do I need my own ChatGPT API access?",
        answer: "During the free Demo you don't, afterwards yes (for now). Getting a ChatGPT API Key is quite easy and is explained in the Tutorials. A managed version where PodPipe makes ChatGPT available for all users is in the making!"
    },
    {
        question: "Any other Prerequisites?",
        answer: "If you want to upscale cropped stickers you will also need a free Vectorizer API Key. This wont be mandatory from around mid September onwards."
    },
    {
        question: "How long does the Setup take?",
        answer: "In the free-trial you can get started right after Sign up. Afterwards it can take up to 25 minutes, here is the break-down: Start Midjourney subscription: ~7 min. ChatGPT API Key: ~5 min. Vectorizer API Key: ~3 min. Provide access to podPipe: ~10 min"
    },
    {
        question: "How are the images generated?",
        answer: "PodPipe uses the Midjourney AI Pro version. Other Image AI tools might be supported in the near future."
    },
    // ... more FAQs
  ];

  return (
    <Box sx={{ marginTop: '30px', marginBottom: '30px'}}>
        <Typography variant="h3" gutterBottom>
            Frequently Asked Questions
        </Typography>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Box>
  );
}

export default FAQPage;
