import { DataStore } from 'aws-amplify';
import { UserTasks } from '../models';


// Omy gotto, doesnt work with arrays!
export const updateUserTasksFields = async (userTasksId, updatedFields) => {
    try {
        const original = await DataStore.query(UserTasks, userTasksId);

        const user_tasks_copy = UserTasks.copyOf(original, (updated) => {
            // Loop through each key in the updatedFields object
            for (const key in updatedFields) {
                if (Object.hasOwnProperty.call(updatedFields, key)) {
                    // Update the corresponding field in the updated object
                    updated[key] = updatedFields[key];
                }
            }
        })

        console.log('user_tasks_copy: ', user_tasks_copy);

        const updatedUserTasks = await DataStore.save(
            UserTasks.copyOf(original, (updated) => {
                // Loop through each key in the updatedFields object
                for (const key in updatedFields) {
                    if (Object.hasOwnProperty.call(updatedFields, key)) {
                        // Update the corresponding field in the updated object
                        updated[key] = updatedFields[key];
                    }
                }
            })
        );
        console.log('Editted updatedUserTasks:\n', updatedUserTasks);
        return updatedUserTasks;
    } catch (error) {
      console.log('Error updating theme!: ', error);
    }
};

const getRegisteredUserAccountNames = (userItem, credential_type) => {
    if (typeof userItem !== 'object' || userItem === null) {
      console.error('User item should be an object and not null');
      return [];
    }
  
    if (userItem.credentials && userItem.credentials[credential_type]) {
      let accountNames = Object.keys(userItem.credentials[credential_type].accounts);
      return accountNames;
    } else {
      return [];
    }
  };
  

export const getAvailableCreationAccounts = (metaUserItem, metaUserTasksItem) => {
    let availableAccounts = {};

    if (metaUserTasksItem?.artworkCreation) {
        const credential_type = 'midj';
        const credential_types = ['midj'];
        credential_types.forEach((credential_type) => {
            const accountNames = getRegisteredUserAccountNames(metaUserItem, credential_type);
            availableAccounts[credential_type] = accountNames;
        });
        metaUserTasksItem.artworkCreation.forEach(task => {
            const task_id = task.task_id
            if (task_id) {
                const parts = task_id.split("_");
                const account_type = parts[0];
                const account_name = parts[1];
                if (availableAccounts.hasOwnProperty(account_type) && availableAccounts[account_type].includes(account_name)) {
                    availableAccounts[account_type] =  availableAccounts[account_type].filter((item) => item !== account_name);
                }
            }
        });
    }

    return availableAccounts;
};