import { DataStore, Storage, SortDirection } from 'aws-amplify';
import { Artwork } from '../models';

// function encodeToUrl(string) {
//   // Encode the string using encodeURIComponent
//   let encodedString = encodeURIComponent(string);
  
//   // Replace spaces with '+'
//   encodedString = encodedString.replace(/%20/g, '+');

//   encodedString = encodedString.replace(/%2F/g, '/');
  
//   return encodedString;
// }

function encodeToUrl(string) {
  // Encode the string using encodeURIComponent
  let encodedString = encodeURIComponent(string);
  
  // Replace encoded spaces with '+'
  encodedString = encodedString.replace(/%20/g, '+');

  encodedString = encodedString.replace(/%2F/g, '/');
  
  // Replace encoded single quotes with the unencoded single quote
  encodedString = encodedString.replace(/%27/g, "'");
  
  // Replace encoded parentheses with the unencoded parentheses
  encodedString = encodedString.replace(/%28/g, "(");
  encodedString = encodedString.replace(/%29/g, ")");
  
  return encodedString;
}

export const fetchSignedImageUrls = async (artwork, userId) => {
  let key_name;
  let processedImageUrl;
  let originalImageUrl;
  if ((artwork.art_type == 'STICKER' || artwork.art_type == 'sticker') && artwork.key_name_processed){
  // if ((artwork.art_type == 'STICKER' || artwork.art_type == 'sticker')){
    key_name = artwork.key_name_processed != null ? artwork.key_name_processed : artwork.key_name_original;
    processedImageUrl = await Storage.get(key_name, {
      level: 'private',
      identityId: userId
    });
    
    originalImageUrl = await Storage.get(artwork.key_name_original, {
      level: 'private',
      identityId: userId
    });
  }else {
    key_name = artwork.key_name_original;
    processedImageUrl = null
    originalImageUrl = await Storage.get(key_name, {
      level: 'private',
      identityId: userId
    });
  }

  let upscaledImageUrl = null;
  if (artwork.hasOwnProperty('key_name_upscaled') && artwork['key_name_upscaled'] !== null){
    upscaledImageUrl = await Storage.get(artwork.key_name_upscaled, {
      level: 'private',
      identityId: userId
    });
  }
  return { ...artwork, processedImageUrl: processedImageUrl, originalImageUrl: originalImageUrl, upscaledImageUrl: upscaledImageUrl, chosenKeyName: key_name };
};

export const fetchArtworks = async (userId, stage, niche, currentPage, pageSize) => {
  console.log('fetch for currentPage: ', currentPage, (currentPage * pageSize) +1, pageSize, stage, userId);
  try {
    const offset = (currentPage * pageSize) +1;
    const themeId = niche == null ? null : niche.id;

    const artworks = await DataStore.query(Artwork, (a) => a.and(a => {
      const queryConditions = [
        a.userID.eq(userId),
        a.stage.eq(stage)
      ];
    
      if (themeId) {
        queryConditions.push(a.themeID.eq(themeId));
      }
    
      return queryConditions;
    }), {
        limit: pageSize,
        offset: offset,
        sort: (a) => a.createdAt(SortDirection.DESCENDING)
    });

    // Fetch image URLs for each artwork
    const updatedArtworks = await Promise.all(
      artworks.map(async (artwork) => {
        return fetchSignedImageUrls(artwork, userId);
      })
    );
    // console.log('updatedArtworks: ', updatedArtworks);
    return updatedArtworks;
  } catch (error) {
    console.log('Error fetching artworks:', error);
    return [];
  }
};

export const updateArtworkStage = async (artwork, newStage) => {
    try {
        // if ('imgUrl' in artwork) {
        //     delete artwork.imgUrl;
        // }
        //TODO: avoipd query!
        const original = await DataStore.query(Artwork, artwork.id);
        
        const updatedArtwork = await DataStore.save(
            Artwork.copyOf(original, updated => {
                updated.stage = newStage
            })
        );
        // await DataStore.save(artwork, Artwork);
        // console.log('Artwork updated:', updatedArtwork);
        return updatedArtwork;
    } catch (error) {
        console.log('Error updating artwork:', error);
        return artwork
    }
};

export const updateArtworkFields = async (artworkId, updatedFields) => {
  try {
    // Fetch the original artwork from the DataStore
    const original = await DataStore.query(Artwork, artworkId);

    const updatedArtwork = await DataStore.save(
      Artwork.copyOf(original, (updated) => {
        // Loop through each key in the updatedFields object
        for (const key in updatedFields) {
          if (Object.hasOwnProperty.call(updatedFields, key)) {
            // Update the corresponding field in the updated object
            updated[key] = updatedFields[key];
          }
        }
      })
    );
    console.log('Editted artwork:\n', updatedArtwork);
    return updatedArtwork;
  } catch (error) {
    console.log('Error updating artwork:', error);
    return null;
  }
};

export const deleteArtwork = async (artworkId) => {
    try {
        const deletedArtwork = await DataStore.delete(Artwork, (a) => a.id.eq(artworkId));
        console.log('Artwork deleted:', deletedArtwork);
    } catch (error) {
        console.log('Error deleting artwork:', error);
    }
};

export const undeleteArtwork = async (artworkId) => {
    try {
      const artwork = await DataStore.query(Artwork, artworkId);
      if (artwork) {
        // artwork._deleted = false;
        // const updatedArtwork = await DataStore.save(artwork);
        const updatedArtwork = await DataStore.save(
            Artwork.copyOf(artwork, updated => {
                updated._deleted = false
            })
        );
        console.log('Artwork undeleted:', updatedArtwork);
      } else {
        console.log('Artwork not found');
      }
    } catch (error) {
      console.log('Error undeleting artwork:', error);
    }
};
  
  
