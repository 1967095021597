import React, { useContext } from 'react';
import { StateContext } from '../StateContext';
import HomeHero from '../components/home/hero';
import HomeExampleArtworks from '../components/home/ExampleArtworks';
import CustomCarousel from '../components/home/CustomCarousel'; //TODO: why needed though..

const HomePage = () => {
  const { 
    headerLoginDialogOpen, setHeaderLoginDialogOpen
  } = useContext(StateContext);

  const handleGetStartedClick = () => {
    setHeaderLoginDialogOpen(true);
  };

  return (
    <>
      <HomeHero handleGetStartedClick={handleGetStartedClick}/>
      <HomeExampleArtworks />
    </>
  );
}

export default HomePage;