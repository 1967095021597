// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { UserTasks, Options, Artwork, Store, Theme, User, StoreArtwork, ThemeStore } = initSchema(schema);

export {
  UserTasks,
  Options,
  Artwork,
  Store,
  Theme,
  User,
  StoreArtwork,
  ThemeStore
};