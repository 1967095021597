import { Amplify, Auth} from 'aws-amplify';
import axios from 'axios';

// const env = Amplify.getEnv();

const env = process.env.REACT_APP_ENV

let apiEndpoint;

switch(env) {
    case 'dev':
        apiEndpoint = process.env.REACT_APP_DEV_REST_ENDPOINT;
        break;
    case 'test':
        apiEndpoint = process.env.REACT_APP_TEST_REST_ENDPOINT;
        break;
    case 'prod':
        apiEndpoint = process.env.REACT_APP_PROD_REST_ENDPOINT;
        break;
    default:
        apiEndpoint = process.env.REACT_APP_DEV_REST_ENDPOINT; // default to dev if not recognized
}

console.log('env: ', env)
console.log('apiEndpoint: ', apiEndpoint)

export const requestCreateArtworksPrompts = async (theme_id, prompts) => {
    
    const payload = {
        instruction_type: "PROMPTS",
        task_id: "midj_1",
        account_name: "",
        theme_id: theme_id,
        prompts: prompts,
        max_duration_seconds: 60000 //16h
    };

    const url = apiEndpoint + '/v1/artwork-creation';
    console.log('url: ', url);

    return await sendRequest(url, payload);
};

export const requestCreateArtworksExplore = async (theme_id, numberOfImages) => {

    const payload = {
        instruction_type: "EXPLORE",
        task_id: "midj_1",
        account_name: "",
        theme_id: theme_id,
        create_n_images: numberOfImages,
        max_duration_seconds: 60000
    };

    const url = apiEndpoint + '/v1/artwork-creation';
    console.log('url: ', url);

    return await sendRequest(url, payload);
};

export const requestStopArtworkCreation = async () => {
    const payload = {
        instruction_type: "STOP",
        task_id: "midj_1"
    };

    const url = apiEndpoint + '/v1/artwork-creation';
    console.log('url: ', url);

    return await sendRequest(url, payload);
};

export const requestGeneratePrompts = async (theme_id, createNPrompts, selectedTopics) => {
    const payload = {
        theme_id: theme_id, 
        create_n_prompts: createNPrompts,
        topics: selectedTopics
    };
    const url = apiEndpoint + '/v1/prompt-generation';
    console.log('url: ', url);

    return await sendRequest(url, payload);
};

export const requestPayForTokens = async (packageName, payment_details) => {
    const payload = {
        package_name: packageName,
        payment_details: payment_details
    };
    const url = apiEndpoint + '/v1/payments';
    console.log('url: ', url);

   return await sendRequest(url, payload);
};

export const requestUpscaleArtworks = async (payload) => {
    const url = apiEndpoint + '/v1/artwork-upscaling';
    console.log('url: ', url);

    return await sendRequest(url, payload);
};

export const requestSaveCredentials = async (credential_type, credentials) => {
    const payload = {
        'credential_type': credential_type,
        'account_name': '1',
        'credentials': credentials
    };
    const url = apiEndpoint + '/v1/credentials';
    console.log('url: ', url);

    const response = await sendRequest(url, payload);

    console.log('API response in requestSaveCredentials:', response);

    return response;
};

export const requestContact = async (email, message) => {
    const payload = {
        email: email,
        message: message,
        env: env
    };
    
    const url = apiEndpoint + '/v1/contact';

    const response = await sendRequest(url, payload);

    return response;
};

const sendRequest = async (url, payload) => {

    const session = await Auth.currentSession();

    console.log('axios payload: ', payload);

    let response = null;
    try {
        response = await axios.post(
            url,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': session.idToken.jwtToken
                }
            }
        );
        console.log('API response:', response.data);
    } catch (error) {
        console.error('API request failed:', error);
    }
    return response;
};