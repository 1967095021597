import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '../StateContext';
import { TextField, Box, Chip, Grid, Button, Collapse, CircularProgress, Typography } from '@mui/material';
import { requestCreateArtworksExplore } from '../services/restApiService';

const CreateArtworksExploreMode = ({selectedNiche, isCreatingArtworks, canUserCreateArtworks, isDemoUser, isDemoCreateAvailable}) => {
  const { tokens, setTokens } = useContext(StateContext);

  const [numberOfImages, setNumberOfImages] = useState(0);
  const [displayNumber, setDisplayNumber] = useState(0);
  const [isCreatable, setIsCreatable] = useState(false);

  const [isSendingCreationRequest, setIsSendingCreationRequest] = useState(false);

  const [isPayable, setIsPayable] = useState(false);

  useEffect(() => {
    if (tokens < numberOfImages) {
      isPayable && setIsPayable(false);
    } else {
      !isPayable && setIsPayable(true);
    }
  }, [numberOfImages, tokens]);

  useEffect(() => {
    if (selectedNiche !== null && 0 < numberOfImages && numberOfImages <= 100 && numberOfImages % 4 === 0) {
      !isCreatable && setIsCreatable(true);
    } else {
      isCreatable && setIsCreatable(false);
    }
  }, [selectedNiche, numberOfImages]);

  const handleNumberOfImagesChange = (event) => {
    const parsedNumber = parseInt(event.target.value, 10);
    if (isNaN(parsedNumber)){
      setNumberOfImages(0);
      setDisplayNumber(event.target.value);
    } else {
     if (0 <= parsedNumber && parsedNumber <= 100) {
      setDisplayNumber(event.target.value);
      setNumberOfImages(parsedNumber);
     } 
    }
  };

  const resetVars = () => {
    handleNumberOfImagesChange({'target': {'value': '0'}})
    setIsSendingCreationRequest(false);
  };

  const handleCreateArtworks = async () => {
    setIsSendingCreationRequest(true);

    await requestCreateArtworksExplore(selectedNiche.id, Math.floor(numberOfImages / 4) * 4);
    
    resetVars();
  };

  return (
    <>
      <Box mt={5} display="flex" flexDirection="row" alignItems="center">
        <TextField
          label="Number of Images, up to 100, divisible by 4"
          type="number"
          value={displayNumber}
          onChange={handleNumberOfImagesChange}
          sx={{ marginRight: 2 }}
          inputProps={{
            step: 4, // Set the step value to 4 to increase/decrease the number by 4
          }}
        />

        <Button variant="contained" color="primary" onClick={handleCreateArtworks} disabled={!isCreatable || isCreatingArtworks || (!isDemoUser && !canUserCreateArtworks) || !isPayable || (isDemoUser && !isDemoCreateAvailable)}>
          {isSendingCreationRequest ? <CircularProgress size={24} color="secondary"/> : 'Create ' + numberOfImages + ' Artworks'}
        </Button>
      </Box>
      {!isPayable && (
        <Typography variant="h6" color='orange'>
          You dont have enough tokens! Pay up :)
        </Typography>
      )}
      {isDemoUser && (
        <Box mt={2}>
        {isDemoCreateAvailable ?  
          <Typography variant="h6" color='green'>
            Demo Image Generation is available !
          </Typography>
        : 
          <Typography variant="h6" color='orange'>
            Other free loaders are occupying the Demo, check in later!
          </Typography>
        }
      </Box>
      )}
    </>
  );
};

export default CreateArtworksExploreMode;
