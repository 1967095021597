import React from 'react';

const Logo = ({style}) => {
  console.log('LOGO path: ', process.env.PUBLIC_URL + '/assets/images/logo.svg');
  return (
    <img src={process.env.PUBLIC_URL + '/assets/images/logo_white.svg'} alt="App Logo" style={style} />
  );
};

export default Logo;
