import React, { useRef, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import {Dialog, DialogContent } from '@mui/material';

const LoginForm = withAuthenticator(() => null);

const AuthenticatorDialog = ({ open, onClose }) => {
  const dialogRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//         console.log('in handleClickOutside, dialogRef.current: \n', dialogRef.current);
//         console.log('in handleClickOutside, event.target: \n', event.target);
//         if (dialogRef.current && !dialogRef.current.contains(event.target)) {
//             onClose();
//         }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
      
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [onClose]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // console.log('in handleClickOutside, dialogRef.current: \n', dialogRef.current);
            // console.log('in handleClickOutside, event.target: \n', event.target);

            // Check if the clicked element itself is a div with the specified attributes
            if (event.target.tagName === 'DIV' && 
                event.target.getAttribute('data-amplify-authenticator') !== null &&
                event.target.getAttribute('data-variation') === 'modal') {
                    
                // Move to the first child element of the clicked element
                let childElement = event.target.firstChild;
                    
                // Check if the child element is a div with the specified attribute
                if (childElement && childElement.tagName === 'DIV' && 
                    childElement.getAttribute('data-amplify-container') !== null) {
                        
                    // Close the dialog
                    if (dialogRef.current && dialogRef.current.contains(event.target)) {
                        onClose();
                    }
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <Dialog open={open} onClose={onClose}>
        <DialogContent ref={dialogRef}>
            <LoginForm />
        </DialogContent>
        </Dialog>
    );
};

export default AuthenticatorDialog;
