import React, { useState, useEffect, useContext, useRef } from 'react';
import { StateContext } from '../StateContext';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Slider, Box, Grid, Typography, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';  // <-- imported Alert
import { requestPayForTokens } from '../services/restApiService';
import TokenPackageCard from '../components/TokenPackageCardNew';
import paymentPackageData from './paymentPackageData';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function PaymentPage() {
  // let chosenPackageName = null;

  const [clickedCardID, setClickedCardID] = useState(null);

  const { userItem } = useContext(StateContext);

  const [tokenAmount, setTokenAmount] = useState(1000);
  const [dollarAmount, setDollarAmount] = useState("1.00");
  const dollarAmountRef = useRef(dollarAmount);
  const chosenPackageNameRef = useRef(null);

  const [selectedPackageID, setSelectedPackageID] = useState('DEMO');

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');  // <-- state for Snackbar

  useEffect(() => {
    if (clickedCardID !== null) {
      const chosenPackage = paymentPackageData[clickedCardID];
      chosenPackageNameRef.current = chosenPackage['name'];
      setDollarAmount(chosenPackage['price']);
      dollarAmountRef.current = chosenPackage['price'].toFixed(2);
      console.log('dollarAmountRef.current: ', dollarAmountRef.current);
    }
  }, [clickedCardID]);

  const handlePaymentApproved = async (actions) => {
    const payment_details = await actions.order.capture();
    console.log('payment details: ', payment_details);
    console.log('clickedCardID: ', clickedCardID);
    console.log('on time retrieved chosenPackageName before send: ', paymentPackageData[clickedCardID]['name']);

    const chosenPackage = paymentPackageData[clickedCardID];
    const currentChosenPackageName = chosenPackage ? chosenPackage['name'] : null;

    if (chosenPackageNameRef.current) {
      try {
        const response = await requestPayForTokens(chosenPackageNameRef.current, payment_details);
        console.log('API response in handlePaymentApproved:', response);
        setSnackbarMessage(response.data.message);
        if (response.status === 200) {
            setSnackbarSeverity('success');
        } else {
            setSnackbarSeverity('error');
        }
      } catch (error) {
        console.error('API request failed:', error);
        setSnackbarMessage('Error during processing payment. Weird! Please contact support to get your tokens!');
        setSnackbarSeverity('error');
      } finally {
        setShowSnackbar(true);
      }
    } else {
      setSnackbarMessage('The Chosen Package Name is invalid!');
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setShowSnackbar(false);
  };

  const getPayPalClientId = () => {
    const env = process.env.REACT_APP_ENV

    let paypalClientId;

    switch(env) {
      case 'dev':
        paypalClientId = process.env.REACT_APP_SANDBOX_PAYPAL_CLIENT_ID;
        break;
      case 'test':
        paypalClientId = process.env.REACT_APP_LIVE_PAYPAL_CLIENT_ID;
        break;
      case 'prod':
        paypalClientId = process.env.REACT_APP_LIVE_PAYPAL_CLIENT_ID;
        break;
      default:
        paypalClientId = process.env.REACT_APP_SANDBOX_PAYPAL_CLIENT_ID; // default to dev if not recognized
    }

    console.log('paypalClientId: ', paypalClientId);
    return paypalClientId;
  };

  return (
    <Box sx={{ width: '100%', marginTop: '30px', marginBottom: '30px', marginRight: '30px', marginLeft: '30px'}}>
      <Typography variant="h4" gutterBottom>
        Token Packages
      </Typography>
      <Typography variant="body1" gutterBottom sx={{width: '50%'}}>
        You can buy Tokens here to generate more amazing AI Art. Once you buy any you will leave the Free-Trial mode. The Tokens you buy will currently not expire, but in the future newly bought ones will.
      </Typography>
      <Box sx={{ marginTop: '30px'}}>
        <Grid container spacing={2}>
          {paymentPackageData.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
              <TokenPackageCard
                title={item.title}
                bgImage={item.bg_image}
                tokens={item.tokens}
                youGet={item.youGet}
                features={item.features}
                missingFeatures={item.missingFeatures}
                cardID={index}
                clickedCardID={clickedCardID}
                handleCardClick={setClickedCardID}
                enabled={item.enabled}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
        * Once you leave the Free-Trial mode, you are required to bring your own Midjourney and ChatGPT account
        <br />
        ** Packages do not consider incurred costs by usage of your private Midjourney and ChatGPT account
      </Typography>
      {clickedCardID !== null && (
        <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
          <Box sx={{ marginTop: '30px'}}>
            <PayPalScriptProvider
              options={{ "client-id": getPayPalClientId() }}
            >
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          currency_code: "USD",
                          value: dollarAmountRef.current,
                        },
                      },
                    ],
                  });
                }}
                onApprove={async (data, actions) => {
                  console.log("onApprove data: ", data);
                  handlePaymentApproved(actions);
                }}
              />
            </PayPalScriptProvider>
          </Box>
        </Box>
      )}

      {/* Added the Snackbar component here */}
      <Snackbar open={showSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default withAuthenticator(PaymentPage);
