import React, { createContext, useState } from 'react';

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokens, setTokens] = useState(0);
  const [userId, setUserId] = useState(null);
  const [userItem, setUserItem] = useState(null);
  const [userTasksItem, setUserTasksItem] = useState(null);
  const [metaUserItem, setMetaUserItem] = useState(null);
  const [metaUserTasksItem, setMetaUserTasksItem] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(false);
  const [open, setOpen] = useState(true); 
  const [headerLoginDialogOpen, setHeaderLoginDialogOpen] = useState(false);

  return (
    <StateContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, tokens, setTokens, userId, setUserId, userItem, setUserItem, userTasksItem, setUserTasksItem, metaUserItem, setMetaUserItem, metaUserTasksItem, setMetaUserTasksItem, isDemoUser, setIsDemoUser, open, setOpen, headerLoginDialogOpen, setHeaderLoginDialogOpen}} // Include 'open' and 'setOpen' in the value
    >
      {children}
    </StateContext.Provider>
  );
};
