import React, { useEffect, useContext, useState } from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { DataStore, Storage, Auth, SortDirection } from 'aws-amplify';
import { Artwork, Theme } from '../models';
import { Button, TextField, Autocomplete, Collapse, Typography, Box, BottomNavigation, CircularProgress } from '@mui/material';
import {fetchArtworks, fetchSignedImageUrls, updateArtworkStage, updateArtworkFields, deleteArtwork} from '../services/artworkService';
import { requestUpscaleArtworks } from '../services/restApiService';
import { StateContext } from '../StateContext';
// import ArtworkCards from '../components/ArtworkCards';
import ArtworkGrid from '../components/ArtworkGrid';
import ExportDialog from '../components/ExportDialog';
import UploadDialog from '../components/UploadDialog';
import ExcelJS from 'exceljs';
import JSZip from 'jszip';

const GalleryPage = () => {
  const { isLoggedIn, userId } = useContext(StateContext);
  const [artworks, setArtworks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [approvedIds, setApprovedIds] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const pageSize = 48;
  
  const [currentArtwork, setCurrentArtwork] = useState(null);
  const [showArtworkDetails, setShowArtworkDetails] = useState(false);

  const [enabledUpscale, setEnabledUpscale] = useState(false);
  const [upscaleCounter, setUpscaleCounter] = useState(0);

  const [showOriginal, setShowOriginal] = useState(false);

  const [displayStage, setDisplayStage] = useState('NEW');
  const stages = ['NEW', 'BACKLOG', 'APPROVED', 'TRASH', 'UPSCALED', 'UPSCALING'];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const [niches, setNiches] = useState([]);
  const [selectedNiche, setSelectedNiche] = useState(null);
  const [selectedNicheName, setSelectedNicheName] = useState(null);

  const [areClicked, setAreClicked] = useState(Array(artworks.length).fill(false));
  const [clickedCounter, setClickedCounter] = useState(0);
  const [selectedStickerCount, setSelectedStickerCount] = useState(0);

  const [enabledUnselect, setEnabledUnselect] = useState(false);
  const [enabledTrash, setEnabledTrash] = useState(true);
  const [enabledBacklog, setEnabledBacklog] = useState(true);
  const [enabledApprove, setEnabledApprove] = useState(true);
  const [enabledExport, setEnabledExport] = useState(false);

  const [isExportDialogOpen, setExportDialogOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [isUpscaleDialogOpen, setIsUpscaleDialogOpen] = useState(false);

  const toggleIsClicked = (index) => {
    const isStickerArtwork = artworks[index]?.art_type === 'STICKER'
    setAreClicked((prevAreClicked) => {
      const updatedAreClicked = [...prevAreClicked];
      const isSelected = !updatedAreClicked[index];
      updatedAreClicked[index] = isSelected
      if (isStickerArtwork) {
        setSelectedStickerCount((prevCounter) => {
          const newStickerCount = isSelected ? prevCounter + 1 : prevCounter - 1;
          console.log('newStickerCount: ', newStickerCount);
          return newStickerCount;
        })
      }
      setClickedCounter((prevClickedCounter) => {
        const updatedClickedCounter = updatedAreClicked.filter((value) => value).length;
        return updatedClickedCounter;
      });
      updatedAreClicked.includes(true) ? setEnabledUnselect(true) : setEnabledUnselect(false);
      return updatedAreClicked;
    });
  };

  const resetSelection = (() => {
    setAreClicked(Array(artworks.length).fill(false));
    setSelectedStickerCount(0);
    setClickedCounter(0);
    setEnabledUnselect(false);
  });

  // const handleScroll = () => {
  //   console.log('handleScroll');
  //   const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  
  //   // Check if the user has scrolled to the bottom
  //   if (scrollTop + clientHeight >= scrollHeight) {
  //     // Perform the action to load the next page of artworks
  //     // loadNextPage();
  //     console.log('LOAD NEXT PAGE BCS OF SCROLLLLIIIINNNNNGUU');
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  
  useEffect(() => {
    resetSelection();
  }, [displayStage, selectedNiche]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (displayStage === 'NEW') {
      setEnabledTrash(true);
      setEnabledBacklog(true);
      setEnabledApprove(true);
      setEnabledUpscale(true);
      setEnabledExport(true);
    } else if (displayStage === 'TRASH') {
      setEnabledTrash(false);
      setEnabledBacklog(true);
      setEnabledApprove(true);
      setEnabledUpscale(true);
      setEnabledExport(true);
    } else if (displayStage === 'BACKLOG') {
      setEnabledTrash(true);
      setEnabledBacklog(false);
      setEnabledApprove(true);
      setEnabledUpscale(true);
      setEnabledExport(true);
    } else if (displayStage === 'APPROVED') {
      setEnabledTrash(true);
      setEnabledBacklog(true);
      setEnabledApprove(false);
      setEnabledUpscale(true);
      setEnabledExport(true);
    } else if (displayStage === 'UPSCALED') {
      setEnabledTrash(true);
      setEnabledBacklog(true);
      setEnabledApprove(true);
      setEnabledUpscale(false);
      setEnabledExport(true);
    } 
    else if (displayStage === 'UPSCALING') {
      setEnabledTrash(true);
      setEnabledBacklog(false);
      setEnabledApprove(true);
      setEnabledUpscale(false);
      setEnabledExport(true);
    }

  }, [displayStage])

  useEffect(() => {
    let subscription;

    const subscribeToThemes = async () => {
      try {
        subscription = DataStore.observeQuery(
          Theme,
          t => t.userID.eq(userId), {
            sort: s => s.createdAt(SortDirection.DESCENDING)
          }
        ).subscribe(snapshot => {
          const { items, isSynced } = snapshot;
          setNiches(items);
        });
      } catch (error) {
        console.error('Failed to fetch initial artworks:', error);
      }
    };

    if (isLoggedIn && userId) {
      subscribeToThemes();
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [isLoggedIn, userId]);

  const loadArtworks = async (stage, niche, currentPage, pageSize) => {
    const fetchedArtworks = await fetchArtworks(userId, stage, niche, currentPage, pageSize);
    // if fetchedArtworks 
    console.log("fetchedArtworks: ", fetchedArtworks);
    // handleSetArtworks(fetchedArtworks);
    setArtworks((prevArtworks) => {
      const updatedArtworks = [...prevArtworks, ...fetchedArtworks];
      return updatedArtworks;
    });
    // setCurrentArtwork(fetchedArtworks[0]);
  };

  useEffect(() => {
    console.log('triggered useeffect');
    if (isLoggedIn && userId) {
      loadArtworks(displayStage, selectedNiche, currentPage, pageSize);
    }
  }, [isLoggedIn, userId, currentPage, displayStage, selectedNiche]);

  useEffect(() => {
    let subscription;

    const subscribeToArtworks = async () => {
      try {
        subscription = DataStore.observe(
          Artwork, (a) => a.and(a => {
            const queryConditions = [
              a.userID.eq(userId),
              a.stage.eq(displayStage)
            ];
          
            if (selectedNiche) {
              queryConditions.push(a.themeID.eq(selectedNiche.id));
            }
          
            return queryConditions;
        })).subscribe(async (msg) => {
          let { model, opType, element } = msg;
          console.log('artwork item change detected: ', opType, element);
          element = await fetchSignedImageUrls(element, userId);
          if (opType == 'UPDATE') {
            handleSetArtworks([element]);
          }
          if (opType == 'INSERT') {
            handleSetArtworks([element]);
          }
        });
      } catch (error) {
        console.error('Failed to fetch initial artworks:', error);
      }
    };

    if (isLoggedIn && userId) {
      subscribeToArtworks();
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [isLoggedIn, userId, displayStage]);

  const handleSetArtworks = (newArtworkItems) => {
    setArtworks((prevArtworks) => {
      let updatedArtworks = [...prevArtworks];
    
      newArtworkItems.forEach((newArtwork) => {
        const existingIndex = prevArtworks.findIndex((artwork) => artwork.id === newArtwork.id);
    
        if (existingIndex !== -1) {
          if (newArtwork.stage != displayStage) {
            updatedArtworks = updatedArtworks.filter((artwork) => artwork.id !== newArtwork.id);
          } else {
            updatedArtworks[existingIndex] = { ...updatedArtworks[existingIndex], ...newArtwork };
          }
        } else {
          updatedArtworks = [newArtwork, ...updatedArtworks];
        }
      });

      console.log('new setCurrentArtwork: \n', updatedArtworks[0]);
      console.log('new artworks length: \n', updatedArtworks.length);
      // setCurrentArtwork(updatedArtworks[0]);
    
      return updatedArtworks;
    });
  }

  const handleEditArtwork = (artworkId, updatedFields) => {
    updateArtworkFields(artworkId, updatedFields);
  };

  const swiped = (direction, swipedArtwork) => {
    if (direction === 'left') {
      swipedArtwork.stage !== 'BACKLOG' && updateArtworkStage(swipedArtwork, 'BACKLOG');
      // handleSetArtworks([updatedArtwork]);
    } else if (direction === 'right') {
      if (swipedArtwork.stage !== 'APPROVED') {
        updateArtworkStage(swipedArtwork, 'APPROVED');
        setApprovedIds((prevIds) => {
          if (!prevIds.includes(swipedArtwork.id)) {
            return [...prevIds, swipedArtwork.id];
          }
          return prevIds;
        });
      }
      // handleSetArtworks([updatedArtwork]);
      setUpscaleCounter(approvedIds.length + 1);
      !enabledUpscale && setEnabledUpscale(true);
        
    } else if (direction === 'down') {
      const updatedArtwork = updateArtworkStage(swipedArtwork, 'TRASH');
      // setDeletedIds((prevIds) => [...prevIds, swipedArtwork.id]);
    } else if (direction === 'up') {
      setArtworks((prevArtworks) => {
        console.log('prevArtworks before: ', prevArtworks);
        const newArtworks = [...prevArtworks];
        const firstArtwork = newArtworks.shift();
        newArtworks.push(firstArtwork);
        console.log('prevArtworks after: ', newArtworks);
        return newArtworks;
      });
      return;
    }

    console.log('remove to remainingArtworks before: ', artworks.length);
    const remainingArtworks = artworks.filter((artwork) => artwork.id !== swipedArtwork.id);
    console.log('remove to remainingArtworks after: ', remainingArtworks.length);
    // remainingArtworks = remainingArtworks == [] ? null : remainingArtworks;
    setArtworks(remainingArtworks == [] ? null : remainingArtworks);
    // setCurrentPage((prevPage) => prevPage + 1);
    // setCurrentArtwork(remainingArtworks[0]);

    // if (remainingArtworks != []) {
    //   setCurrentArtwork(remainingArtworks[0]);
    // }else {
    //   setCurrentArtwork(null);
    // }
    
    console.log('is time to load next wave? ', artworks?.length);
    // if (artworks.length < 2){
    //   console.log('setCurrentPage +1');
    //   setCurrentPage((prevPage) => prevPage + 1);
    // }
  };

  const outOfFrame = (artwork) => {
    console.log(artwork.title + " left the screen");
  };

  const handleShowOtherImageStage = () => {
    setShowOriginal(!showOriginal);
  }

  const resetDisplayVars = () => {
    setApprovedIds([]);
    setUpscaleCounter(0);
    setEnabledUpscale(false);
    isExportDialogOpen && setIsExporting(false);
  };

  const handleUnselect = () => {
    setAreClicked((prevAreClicked) => {
      const updatedAreClicked = Array(artworks.length).fill(false);
      setClickedCounter((prevClickedCounter) => {
        const updatedClickedCounter = updatedAreClicked.filter((value) => value).length;
        return updatedClickedCounter;
      });
      return updatedAreClicked;
    });
    setEnabledUnselect(false);
  };

  const handleSelectUnselectAll = () => {
    setAreClicked((prevAreClicked) => {
      const updatedAreClicked = Array(artworks.length).fill(!enabledUnselect);
      setClickedCounter((prevClickedCounter) => {
        const updatedClickedCounter = updatedAreClicked.filter((value) => value).length;
        return updatedClickedCounter;
      });

      var countedStickers = 0;
      if (!enabledUnselect) {
        updatedAreClicked.forEach((val, index) => {
          const isStickerArtwork = artworks[index]?.art_type === 'STICKER'
          if (isStickerArtwork) {
            countedStickers += 1;
          }
        });
      }
      setSelectedStickerCount(countedStickers);

      return updatedAreClicked;
    });
    setEnabledUnselect(!enabledUnselect);
  };

  // const handleUpscaleDesktop = async (standardScaleFactor, stickerScaleFactor) => {

  //   standardScaleFactor = parseInt(standardScaleFactor);
  //   stickerScaleFactor = parseInt(stickerScaleFactor);

  //   if (isNaN(standardScaleFactor) || isNaN(stickerScaleFactor)) {
  //     return;
  //   }

  //   handleMoveToStage('UPSCALING');

  //   let payload = {'artworks': {}}; //TODO: 

  //   areClicked.forEach((isClicked, index) => {
  //     if (isClicked){
  //       const tmpArtworkId = artworks[index].id;
  //       const tmpScaleFactor = artworks[index].art_type === 'STICKER' ? stickerScaleFactor : standardScaleFactor;
  //       payload.artworks[tmpArtworkId] = {scale_factor: tmpScaleFactor}
  //     }
  //   });

  //   console.log('payload for upload: \n', payload);
  //   await requestUpscaleArtworks(payload);
  // };

  const handleUpscaleDesktop = async (standardResArr, stickerResArr) => {

    handleMoveToStage('UPSCALING');

    let payload = {'artworks': {}};

    areClicked.forEach((isClicked, index) => {
      if (isClicked){
        const tmpArtworkId = artworks[index].id;
        const tmpResArr = artworks[index].art_type === 'STICKER' ? stickerResArr : standardResArr;
        payload.artworks[tmpArtworkId] = {target_resolution: tmpResArr}
      }
    });

    console.log('payload for upload: \n', payload);
    await requestUpscaleArtworks(payload);
  };

  const handleUpscale = async () => {
    if (approvedIds.length == 0) {
      console.log('no artworks selected!');
      return;
    }
    // resetDisplayVars();
    // deletedIds.forEach((artworkId) => {
    //   deleteArtwork(artworkId)
    // });

    // let payload = {'artworks': {}}; //TODO: 

    // approvedIds.forEach((artworkId) => {
    //   payload.artworks[artworkId] = {scale_factor: 8}
    // });

    // await requestUpscaleArtworks(payload);
  };

  const handleMoveToStage = (newStage) => {
    const movedArtworks = [];
    areClicked.forEach((isClicked, index) => {
      if (isClicked){
        const tmpArtwork = artworks[index];
        updateArtworkStage(tmpArtwork, newStage);
        movedArtworks.push(tmpArtwork);
      }
    });
    const remainingArtworks = artworks.filter((artwork) => !movedArtworks.includes(artwork));
    setArtworks(remainingArtworks == [] ? null : remainingArtworks);
    resetSelection();
  };

  const handleShowDetails = () => {
    setShowArtworkDetails(!showArtworkDetails);
  };

  const handleStageSelect = (event, value) =>{
    setArtworks([]);
    setDisplayStage(value);
    setCurrentPage(0);
  };

  const handleNicheSelect = (event, value) => {
    setArtworks([]);
    setSelectedNiche(value);
    setSelectedNicheName(value?.name || 'no name field');
    setCurrentPage(0);
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  const handleOpenUpscaleDialog = () => {
    setIsUpscaleDialogOpen(true);
  };

  const handleCloseUpscaleDialog = () => {
    setIsUpscaleDialogOpen(false);
  };

  const generateExcelFile = async (artworksForDownload, excelFileNamePrefix) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    
    worksheet.columns = [
      { header: 'Image Path', key: 'imgPath', width: 15 },
      { header: 'Language', key: 'language', width: 10 },
      { header: 'Title', key: 'title', width: 15 },
      { header: 'Description', key: 'description', width: 10 },
      { header: 'Tags', key: 'tags', width: 15 },
      { header: 'Type', key: 'type', width: 10 },
      { header: 'Color', key: 'color', width: 10 }
    ];

    artworksForDownload.forEach((artwork) => {
      if (artwork.hasOwnProperty('key_name_original') && artwork['key_name_original'] !== null){
        worksheet.addRow({ 
          imgPath: cleanFileName(excelFileNamePrefix + artwork['key_name_original'].split('/').slice(-1)[0]), 
          language: 'EN',
          title: artwork['title'],
          description: artwork['description'],
          tags: artwork['keywords'],
          type: 'man, woman, youth',
          color: 'black'
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    return blob;
  };

  // function cleanFileName(fileName) {
  //   // List of invalid characters that you want to clean
  //   // const invalidChars = ['<', '>', ':', '"', '/', '\\', '|', '?', '*'];
  //   const invalidChars = ['<', '>', ':', '"', '/', '\\\\', '|', '\?', '\*'];

  //   // Replace each invalid character with an empty string
  //   invalidChars.forEach((char) => {
  //       fileName = fileName.replace(new RegExp(char, 'g'), '');
  //   });

  //   return fileName;
  // }

  function cleanFileName(fileName) {
    // List of invalid characters that you want to clean
    const invalidChars = ['<', '>', ':', '"', '/', '\\', '|', '?', '*'];

    // Replace each invalid character with an empty string
    invalidChars.forEach((char) => {
        fileName = fileName.replace(new RegExp('\\' + char, 'g'), '');
    });

    // Remove trailing spaces or periods
    fileName = fileName.replace(/[\s.]+$/, '');

    return fileName;
}


  const handleExport = async (checkedOriginal, checkedProcessed, checkedUpscaled, excelStage) => {

    setIsExporting(true);

    const zip = new JSZip();

    const artworksForDownload = [];

    areClicked.forEach((isClicked, index) => {
      if (isClicked){
        artworksForDownload.push(artworks[index]);
      }
    });

    const excelFileNamePrefix = excelStage + '_';
  
    const xlsxData = await generateExcelFile(artworksForDownload, excelFileNamePrefix);
    zip.file('data.xlsx', xlsxData);

    const downloadPromises = artworksForDownload.map(async (artwork) => {
      if (
        checkedUpscaled &&
        artwork.hasOwnProperty('key_name_upscaled') &&
        artwork['key_name_upscaled'] !== null
      ) {
        try {

          if (artwork.hasOwnProperty('key_name_upscaled') && artwork['key_name_upscaled'] !== null && artwork['key_name_upscaled'] !== '') {
            const key = artwork['key_name_upscaled'];
            try {
              const response = await Storage.get(key, {
                download: true,
                level: 'private',
                identityId: userId,
              });
              const fileData = response.Body;
              const fileName = cleanFileName('UPSCALED_' + key.split('/').slice(-1)[0]);

              zip.file(fileName, fileData);
            }catch (error) {
              console.log('ERROR downloading UPSCALED IMAGE FOR KEY NAME: ', artwork['key_name_upscaled']);
            }
          }
        } catch (error) {
          console.error('Error downloading S3 file:', error);
        }
      }

      if (checkedOriginal) {
        const keyOriginal = artwork['key_name_original'];
        try {
          const responseOriginal = await Storage.get(keyOriginal, {
            download: true,
            level: 'private',
            identityId: userId,
          });
          const fileDataOriginal = responseOriginal.Body;
          const fileNameOriginal = cleanFileName('ORIGINAL_' + keyOriginal.split('/').slice(-1)[0]);
          zip.file(fileNameOriginal, fileDataOriginal);
        } catch (error) {
          console.log('ERROR downloading ORIGINAL IMAGE FOR KEY NAME: ', artwork['key_name_original']);
        }
      }
      
      if (checkedProcessed && artwork.hasOwnProperty('key_name_processed') && artwork['key_name_processed'] !== null && artwork['key_name_processed'] !== '') {
        const keyProcessed = artwork['key_name_processed'];
        try {
          const responseProcessed = await Storage.get(keyProcessed, {
            download: true,
            level: 'private',
            identityId: userId,
          });
          const fileDataProcessed = responseProcessed.Body;
          const fileNameProcessed = cleanFileName('PROCESSED_' +  keyProcessed.split('/').slice(-1)[0]);
          zip.file(fileNameProcessed, fileDataProcessed);
        } catch (error) {
          console.log('ERROR downloading PROCESSED IMAGE FOR KEY NAME: ', artwork['key_name_processed']);
        }
      }
    });

    // Wait for all the files to be downloaded and added to the zip archive
    await Promise.all(downloadPromises);
  
    // Generate the zip file
    const zipFile = await zip.generateAsync({ type: 'blob' });
  
    // Create a download link and trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipFile);
    const currentTime = new Date().toISOString();
    downloadLink.download = `podpipe_export_${currentTime}.zip`;
    downloadLink.click();
    
    resetDisplayVars();
  };

  return (
    <>
      {isMobile ? 
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Mobile version is being created still
          </Typography>
        </Box>
       : (
        // Desktop View
        <Box sx={{ marginTop: '30px', marginBottom: '30px'}}>
          <Typography variant="h4" gutterBottom>
              {'Artwork Gallery'}
          </Typography>
          <Box marginBottom={4} display='flex' flexDirection="row">
            <Autocomplete
              options={stages}
              value={displayStage}
              onChange={handleStageSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Stage"
                  variant="outlined"
                  autoComplete="off"
                  style={{ width: '200px' }} // Adjust the width value as needed
                />
              )}
            />
            <Autocomplete
              options={niches}
              value={selectedNiche}
              onChange={handleNicheSelect}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Specify Niche"
                  variant="outlined"
                  autoComplete="off"
                  style={{ width: '200px' }} // Adjust the width value as needed
                />
              )}
            />
          </Box>
          {artworks && artworks.length > 0 ? (
            // <StaticArtworkCard artwork={artworks[0]} />
            <Box sx={{ paddingBottom: '64px' }}>
              <ArtworkGrid artworks={artworks} areClicked={areClicked} toggleIsClicked={toggleIsClicked} handleEditArtwork={handleEditArtwork}/>
              <BottomNavigation sx={{ 
                position: 'fixed',
                bottom: 0,
                left: '191px', //175 SideNavBar +16
                right: '16px',
                marginBottom: '16px',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                borderRadius: '16px',
                backgroundColor: 'rgba(220, 220, 220, 0.7)' 
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* <Button variant="contained" color="primary" onClick={handleUnselect} disabled={!enabledUnselect} sx={{ marginRight: '30px' }}>
                      {'Unselect ' + clickedCounter}
                  </Button> */}
                  <Button variant="contained" color="primary" onClick={handleSelectUnselectAll} sx={{ marginRight: '30px' }}>
                      {enabledUnselect? 'Unselect ' + clickedCounter : 'Select All'}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleMoveToStage('TRASH')} disabled={!enabledTrash || !enabledUnselect} sx={{ marginRight: '30px' }}>
                    {'To Trash ' + clickedCounter}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleMoveToStage('BACKLOG')} disabled={!enabledBacklog || !enabledUnselect} sx={{ marginRight: '30px' }}>
                    {'To Backlog ' + clickedCounter}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleMoveToStage('APPROVED')} disabled={!enabledApprove || !enabledUnselect} sx={{ marginRight: '30px' }}>
                    {'To Approved ' + clickedCounter}
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleOpenUpscaleDialog} disabled={!enabledUpscale || !enabledUnselect} sx={{ marginRight: '30px' }}>
                    {'Upscale ' + clickedCounter}
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleOpenExportDialog} disabled={!enabledExport || !enabledUnselect}>
                    {isExporting ? <CircularProgress size={24} color="secondary"/> : 'Export ' + clickedCounter}
                  </Button>
                </Box>
              </BottomNavigation>
            </Box>
          ) : (
            <Typography variant="h5" gutterBottom>
              {'No ' + displayStage + ' Artworks present yet'}
            </Typography>
          )}

          <ExportDialog open={isExportDialogOpen} artworkStage={displayStage} onClose={handleCloseExportDialog} handleExport={handleExport}/>

          <UploadDialog open={isUpscaleDialogOpen} artworkStage={displayStage} onClose={handleCloseUpscaleDialog} handleUpscale={handleUpscaleDesktop} clickedCounter={clickedCounter} selectedStickerCount={selectedStickerCount}/>
        </Box>
      )}
    </>
  );
};

export default withAuthenticator(GalleryPage);
