import { createTheme } from '@mui/material/styles';

const getTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: darkMode ? '#0888B9' : '#0888B9',
    },
    secondary: {
      main: darkMode ? '#D0392F' : '#D0392F',
    },
    text: {
      primary: darkMode ? '#7D7D7D' : '#7D7D7D',
      secondary: darkMode ? '#7D7D7D' : '#7D7D7D',
      homePrimary: darkMode ? '#0888B9' : '#0888B9',
    },
    background: {
      homeDivider: '#F3F5F5'
    }
  }
});

export default getTheme;
