import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import React from 'react';
// Amplify.configure(awsExports);


const LoginPage2 = ({ signOut, user }) => {
  return (
    <div>
      <h1>Welcome!</h1>
      <button onClick={signOut}>Logout</button>
      </div>
  );
}

export default withAuthenticator(LoginPage2);