import React, { useState, useEffect } from 'react';
import { Typography, Autocomplete, TextField, Chip, FormControlLabel, Checkbox, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import nicheOptionDescriptions from './nicheOptionDescriptions';
import { Box } from '@mui/system';

export const MidjParameterSelect = ({ infoKey, label, options, defaultValues, value, onChange, isEditable, startWithDefault, allowMultiple }) => {
    const [useDefault, setUseDefault] = useState(startWithDefault);
    const infoText = nicheOptionDescriptions[infoKey];
  
    useEffect(() => {
      if (defaultValues && useDefault) {
        onChange(defaultValues);
      }
    }, [defaultValues, onChange, useDefault]);
  
    const handleCheckboxChange = (event) => {
      setUseDefault(event.target.checked);
      if (!event.target.checked) {
        allowMultiple ? onChange([]) : onChange(null);
      } else {
        onChange(defaultValues);
      }
    };
  
    const handleSelectionChange = (event, selectedValues) => {
      onChange(selectedValues);
    };
  
    return (
      <div style={{ marginBottom: '30px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={infoText} arrow>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" style={{ marginRight: '10px' }}>{label}</Typography>
          <FormControlLabel
            control={<Checkbox checked={useDefault} onChange={handleCheckboxChange} />}
            label="Use Default"
            disabled={!isEditable} // Disable the checkbox based on isEditable state
          />
        </div>
        <Autocomplete
          disabled={!isEditable}
          multiple={allowMultiple}
          options={options}
          value={value}
          onChange={handleSelectionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Items"
              variant="outlined"
              disabled={!isEditable} // Disable the input field based on isEditable state
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          limitTags={allowMultiple ? 5 : undefined} 
        />
      </div>
    );
};
 
export const MidjTextParameterSelect = ({ infoKey, label, startOptions, defaultValues, value, onChange, isEditable, startWithDefault, allowMultiple, maxLength, mode }) => {
  const infoText = nicheOptionDescriptions[infoKey];
  const [useDefault, setUseDefault] = useState(startWithDefault);
  const [options, setOptions] = useState(startOptions || []);
  const [newText, setNewText] = useState('');
  const [ratioValue1, setRatioValue1] = useState('');
  const [ratioValue2, setRatioValue2] = useState('');

  useEffect(() => {
    if (defaultValues && useDefault) {
      onChange(defaultValues);
    }
  }, [defaultValues, onChange, useDefault]);

  const handleCheckboxChange = (event) => {
    setUseDefault(event.target.checked);
    if (!event.target.checked) {
      allowMultiple ? onChange([]) : onChange(null);
    } else {
      onChange(defaultValues);
    }
  };

  const handleSelectionChange = (event, selectedValues) => {
    onChange(selectedValues);
  };

  const handleAddText = () => {
    if (newText.trim() !== '' && !options.includes(newText.trim())) {
      setOptions([...options, newText.trim()]);
      onChange([...value, newText.trim()]);
      setNewText('');
    }
  };

  const handleAddRatio = () => {
    const ratio1 = parseFloat(ratioValue1);
    const ratio2 = parseFloat(ratioValue2);

    if (!isNaN(ratio1) && !isNaN(ratio2)) {
      const newRatio = `${ratio1}:${ratio2}`;
      setOptions([...options, newRatio]);
      setRatioValue1('');
      setRatioValue2('');
      onChange([...value, newRatio]);
    }
  };

  return (
    // <div>
    <div style={{ marginBottom: '30px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}> 
        <Tooltip title={infoText} arrow>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h6" style={{ marginRight: '10px' }}>{label}</Typography>
        <FormControlLabel
          control={<Checkbox checked={useDefault} onChange={handleCheckboxChange} />}
          label="Use Default"
          disabled={!isEditable}
        />
      </div>
      <Autocomplete
        disabled={!isEditable}
        multiple={allowMultiple}
        options={options}
        value={value}
        onChange={handleSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Items"
            variant="outlined"
            disabled={!isEditable}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
        limitTags={allowMultiple ? 5 : undefined}
      />
      {isEditable && mode !== 'ratios' && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <TextField
            value={newText}
            onChange={(event) => setNewText(event.target.value)}
            label="Add Text"
            variant="outlined"
            disabled={!isEditable || value.length >= maxLength}
            style={{ flexGrow: 1, marginRight: '5px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddText}
            disabled={!isEditable || value.length >= maxLength || newText.trim() === ''}
            style={{ flexGrow: 0 }}
          >
            Add
          </Button>
        </div>
      )}
      {isEditable && mode === 'ratios' && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <TextField
            value={ratioValue1}
            onChange={(event) => setRatioValue1(event.target.value)}
            label="Ratio Value 1"
            variant="outlined"
            type="number"
            disabled={!isEditable || value.length >= maxLength}
          />
          <Typography variant="h6" style={{ margin: '0 10px' }}>:</Typography>
          <TextField
            value={ratioValue2}
            onChange={(event) => setRatioValue2(event.target.value)}
            label="Ratio Value 2"
            variant="outlined"
            type="number"
            disabled={!isEditable || value.length >= maxLength}
            style={{ marginRight: '5px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddRatio}
            disabled={!isEditable || value.length >= maxLength || ratioValue1 === '' || ratioValue2 === ''}
          >
            Add
          </Button>
        </div>
      )}
    </div>
  );
};

  
export const AestheticSelect = ({ infoKey, label, options, value, onChange, isEditable }) => {
  const infoText = nicheOptionDescriptions[infoKey];
  const handleSelectionChange = (event, selectedValues) => {
    onChange(selectedValues);
  };

  return (
    <div style={{ marginBottom: '30px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={infoText} arrow>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h6">{label}</Typography>
      </div>
      <Autocomplete
        multiple
        disabled={!isEditable}
        options={options}
        value={value}
        onChange={handleSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Items"
            variant="outlined"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
      />
    </div>
  );
};

export const MultipleDictSelect = ({ infoKey, label, options, value, onChange, isEditable }) => {
  const infoText = nicheOptionDescriptions[infoKey];
  const handleSelectionChange = (event, selectedValues) => {
    onChange(selectedValues);
  };

  return (
    <div style={{ marginBottom: '30px' }}>
      <Tooltip title={infoText} arrow>
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h6">{label}</Typography>
      <Autocomplete
        multiple
        disabled={!isEditable}
        options={options}
        getOptionLabel={(option) => option.name}
        value={value}
        onChange={handleSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Items"
            variant="outlined"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
      />
    </div>
  );
};
  
export const FreeSoloAutoComplete = ({ infoKey, label, options, value, setValues, isEditable }) => {
  const infoText = nicheOptionDescriptions[infoKey];

  const handleSelectionChange = (event, selectedValues) => {
    setValues(selectedValues);
  };

  const handleAddToSelected = (newValue) => {
    setValues((prevValues) => [...prevValues, newValue]);
  };

  return (
    <div style={{ marginBottom: '30px' }}>
      <Box display='flex'>
        <Tooltip title={infoText} arrow>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h6">
          {label}
        </Typography>
      </Box>
      
      <Autocomplete
        multiple
        freeSolo
        disabled={!isEditable}
        options={options}
        getOptionLabel={(option) => option.name}
        value={value}
        onChange={handleSelectionChange}
          // Allow user input not listed in dropdown
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose or write up to 4 topics!"
            variant="outlined"
            autoComplete="off"
            onKeyDown={(event) => {
              // if the user presses the Enter key
              if (event.key === 'Enter') {
                handleAddToSelected(event.target.value);
                event.target.value = '';  // Reset input field
                event.preventDefault();   // Prevent form submission or any other default behavior
              }
            }}
          />
        )}
      />
    </div>
  );
};

export const OptionsSelect = ({
  infoText, label, options, optionDisplayKey,
  defaultValue, value, setValue, onSelected = null, 
  onAdded = null, isEditable, startWithDefault, allowMultiple = true, 
  allowFreeSolo = false, isValidInputFunc = null
}) => {
  // console.log('initial props: ', label, isValidInputFunc);
  const [useDefault, setUseDefault] = useState(startWithDefault);
  const [inputValue, setInputValue] = useState('');


  const valuesAreEqual = (v1, v2) => {
    return JSON.stringify(v1) === JSON.stringify(v2);
  }

  const handleSelectionChange = (event, newValue) => {
    if (!valuesAreEqual(newValue, defaultValue)) {
      setUseDefault(false);
    }

    if (onSelected) {
      onSelected(setValue, newValue);
    } else {
      setValue(newValue);
    }
    setInputValue(''); 
  };

  const handleAddToSelected = (newValue) => {
    setUseDefault(false);
    if (allowMultiple) {
      if (onAdded) {
        onAdded(setValue, newValue);
      } else {
        setValue((prevValue) => [...prevValue, newValue]);
      }
    } else {
      setValue(newValue);
    }
    setInputValue(''); 
  };

  const handleCheckboxChange = (event) => {
    setUseDefault(event.target.checked);
    if (!event.target.checked) {
      setValue(allowMultiple ? [] : null);
    } else {
      setValue(defaultValue);
    }
  };

  useEffect(() => {
    if (defaultValue && useDefault) {
      setValue(defaultValue);
    }
  }, [useDefault]);

  return (
    <div style={{ marginBottom: '30px' }}>
      <Box sx = {{display: 'flex', alignItems: 'center'}} >
        <Tooltip title={infoText} arrow>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h6">
          {label}
        </Typography>
        {defaultValue && (
          <FormControlLabel
            sx={{marginLeft: '3px'}}
            control={<Checkbox checked={useDefault} onChange={handleCheckboxChange} />}
            label="Use Default"
            disabled={!isEditable} // Disable the checkbox based on isEditable state
          />
        )}
      </Box>
      
      <Autocomplete
        multiple={allowMultiple}
        // freeSolo={allowFreeSolo}
        disabled={!isEditable}
        options={options}
        getOptionLabel={(option) => optionDisplayKey ? option[optionDisplayKey] : option}
        value={value}
        onChange={handleSelectionChange}
        inputValue={inputValue}
          // Allow user input not listed in dropdown
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
        // onInputChange={(event, newInputValue) => {
        //   if (newInputValue !== '') {
        //     useDefault && setUseDefault(false);
        //   }
        // }}
        onInputChange={(event, newInputValue) => {
          if (isValidInputFunc) {
            if (isValidInputFunc(newInputValue) || newInputValue === '') {
              setInputValue(newInputValue); // only set valid values or empty strings
            }
          } else {
            setInputValue(newInputValue); // if no validation function is provided, just set the value
          }
        
          if (newInputValue !== '') {
            useDefault && setUseDefault(false);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose or write up to 4 topics!"
            variant="outlined"
            autoComplete="off"
            onKeyDown={(event) => {
              // if the user presses the Enter key
              if (event.key === 'Enter' && allowFreeSolo) {
                console.log('is in first if');
                if (!isValidInputFunc || isValidInputFunc(event.target.value)) {
                  console.log('is in nested if');
                  if (isValidInputFunc) {
                    console.log('isValidInputFunc? ', isValidInputFunc(event.target.value));
                  } else {
                    console.log('is NOT isValidInputFunc');
                  }
                  options = [...options, event.target.value];
                  handleAddToSelected(event.target.value);
                  event.target.value = '';
                  event.preventDefault(); 
                }
              }
            }}
          />
        )}
        limitTags={allowMultiple ? 5 : undefined}
      />
    </div>
  );
};
