import React, { useState, useEffect } from 'react';
import { Box, Collapse, Typography, IconButton, Dialog, DialogContent, TextField, Chip, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import ContrastIcon from '@mui/icons-material/Contrast';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

const convertToResolutionStr = (resolutionArray) => {
  return "" + resolutionArray[0] + "x" + resolutionArray[1];
};

const StaticArtworkCard = ({ artwork, isClicked, toggleIsClicked, handleEditArtwork }) => {
  const { processedImageUrl, originalImageUrl, art_type, prompt, source_url, title: initialTitle, keywords: initialKeywords, description: initialDescription } = artwork;
  
  const isSticker = art_type == 'STICKER' ? true : false;
  const originalResolution = artwork.original_res ? convertToResolutionStr(artwork.original_res) : null;
  const upscaledResolution = artwork.upscaled_res ? convertToResolutionStr(artwork.upscaled_res) : null;

  const [isBlackBackground, setIsBlackBackground] = useState(false);

  const [title, setTitle] = useState(initialTitle);
  const [keywords, setKeywords] = useState(initialKeywords);
  const [description, setDescription] = useState(initialDescription);

  const [newKeyword, setNewKeyword] = useState('');
  const [keywordsArray, setKeywordsArray] = useState(keywords.split(',').map((keyword) => keyword.trim()));

  //TODO: true or false depending on sticker
  const [displayUrl, setDisplayUrl] = useState(isSticker ? processedImageUrl : originalImageUrl);
  const [showOriginal, setShowOriginal] = useState(isSticker ? false : true);
  const [expanded, setExpanded] = useState(false);
  const [expandedPrompt, setExpandedPrompt] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [urlCopied, setUrlCopied] = useState(false);

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [imageStyle, setImageStyle] = useState({ maxWidth: '100%', height: 'auto' });
  const [dialogImageStyle, setDialogImageStyle] = useState({ maxWidth: '100%', height: 'auto' }); // Default

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImageDimensions({ width: naturalWidth, height: naturalHeight });

    if (naturalWidth > naturalHeight) {
      // For wide images (landscape)
      setImageStyle({ width: '100%', height: 'auto' });
      setDialogImageStyle({ width: '100%', height: 'auto' });
    } else {
      // For tall images (portrait)
      setImageStyle({ maxWidth: '100%', height: 'auto', width: 'auto' });
      setDialogImageStyle({ maxWidth: '100%', height: '80vh', width: 'auto' });
    }
  };

  useEffect(() => {
    showOriginal ? setDisplayUrl(originalImageUrl) : setDisplayUrl(processedImageUrl);
  }, [showOriginal]);

  const handleClick = () => {
    console.log('clicked artwork item: ', artwork);
    toggleIsClicked();
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandPromptClick = () => {
    setExpandedPrompt(!expandedPrompt);
  };

  const handleSwitchArtworkStage = () => {
    setShowOriginal(!showOriginal);
  };

  const handleSwitchBackground = () => {
    setIsBlackBackground(!isBlackBackground);
  };

  const handleShowZoomIn = () => {
    setFullscreen(true);
  };

  const handleCloseFullscreen = () => {
    setFullscreen(false);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setExpanded(true); // Automatically expand the Collapse when entering edit mode
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    const newKeywords = keywordsArray.join(', ');
    setKeywords(keywordsArray.join(', '));
    const updatedFields = {
      title: title,
      keywords: newKeywords,
      description: description,
    };
    handleEditArtwork(artwork.id, updatedFields);
  };

  const handleCancelClick = () => {
    setTitle(initialTitle);
    setKeywords(initialKeywords);
    setDescription(initialDescription);
    setKeywordsArray(initialKeywords.split(',').map((keyword) => keyword.trim()));
    setNewKeyword('');
    setIsEditing(false);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const handleAddNewKeyword = () => {
    if (newKeyword.trim() !==  ''){
      keywordsArray.push(newKeyword.trim());
      setKeywordsArray([...keywordsArray]);
      setNewKeyword('');
    }
  }

  const handleDeleteKeyword = (index) => {
    keywordsArray.splice(index, 1);
    setKeywordsArray([...keywordsArray]); // Update the state to trigger a re-render
  }

  const truncateURL = (url) => {
    let parts = url.split('/');
    return `${parts[0]}//${parts[2]}/.../${parts[parts.length - 1].slice(-5)}`;
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(source_url);
    setUrlCopied(true);
    setTimeout(() => setUrlCopied(false), 2000); // Reset after 2 seconds
  }

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 345,
        cursor: 'pointer',
        border: isClicked ? '2px solid blue' : '1px solid gray',
        boxShadow: '0 0 5px 2px rgba(120, 120, 120, 0.5)',
      }}
      onClick={handleClick}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={isBlackBackground ? 'black' : 'white'}
        width="100%"
        height="auto" // Or set to a fixed height if you want a specific height
      >
        <img
          src={displayUrl}
          title={title}
          alt={title}
          style={imageStyle}
          onLoad={handleImageLoad}
        />
      </Box>
      <CardContent>
        {isEditing ? ( // Show editable view when isEditing is true
          <>
            <TextField
              label="Edit Title"
              value={title} // Use the title from artwork as initial value
              onChange={handleTitleChange} // Update the artwork's title on change
              variant="standard"
              fullWidth
              autoFocus
              sx={{ marginTop: 1 }}
            />
            <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 'bold', marginTop: 2 }}>
              Edit Keywords:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
              {keywordsArray.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword}
                  onDelete={() => {
                    handleDeleteKeyword(index);
                  }}
                />
              ))}
            </Box>
            <TextField
              value={newKeyword} // Use a newKeyword state variable to keep track of the new keyword input
              onChange={(e) => setNewKeyword(e.target.value)}
              variant="standard"
              fullWidth
              onKeyUp={(e) => {
                // Add the new keyword when the user presses "Enter" (key code 13)
                if (e.key === 'Enter') {
                  handleAddNewKeyword();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="add keyword"
                    onClick={() => {
                      handleAddNewKeyword();
                    }}
                  >
                    <DoneIcon />
                  </IconButton>
                ),
              }}
              // sx={{ marginTop: 1 }}
            />
            <TextField
              label="Edit Description"
              value={description} // Use the description from artwork as initial value
              onChange={handleDescriptionChange} // Update the artwork's description on change
              variant="standard"
              fullWidth
              multiline
              sx={{ marginTop: 1 }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 1,
                alignItems: 'center',
              }}
            >
              <IconButton aria-label="cancel" onClick={handleCancelClick}>
                <CancelIcon />
              </IconButton>
              <IconButton aria-label="save" onClick={handleSaveClick}>
                <DoneIcon />
              </IconButton>
            </Box>
          </>
        ) : ( // Show Typography view when isEditing is false
          <>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <IconButton aria-label="zoom in" onClick={handleShowZoomIn}>
                <ZoomInIcon />
              </IconButton>
              {isSticker && (
                <div>
                  <IconButton aria-label="switch view" onClick={handleSwitchArtworkStage}>
                    {showOriginal ? <SwitchLeftIcon /> : <SwitchRightIcon />}
                  </IconButton>
                  <IconButton aria-label="switch view" onClick={handleSwitchBackground}>
                    <ContrastIcon />
                  </IconButton>
                </div>
              )}
              <IconButton aria-label="edit" onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {originalResolution && (
                  <div>
                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1, fontWeight: 'bold' }}>
                      {'Original Resolution: ' + originalResolution}
                    </Typography>
                  </div>
                )}
                {upscaledResolution && (
                  <div>
                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1, fontWeight: 'bold' }}>
                      {'Upscaled Resolution: ' + upscaledResolution}
                    </Typography>
                  </div>
                )}
                <Typography variant="subtitle2" color="text.secondary" sx={{ marginTop: 1, fontWeight: 'bold' }}>
                  Tags:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {keywords}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" sx={{ marginTop: 1, fontWeight: 'bold' }}>
                  Description:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ marginTop: 1, fontWeight: 'bold' }}>
                    Prompt:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {prompt}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                      URL:
                    </Typography>
                    <Tooltip title={urlCopied ? 'Copied!' : truncateURL(source_url)}>
                      <IconButton onClick={handleCopy}>
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
              </Collapse>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ExpandMoreIcon
                sx={{ cursor: 'pointer' }}
                onClick={handleExpandClick}
                color={expanded ? 'primary' : 'inherit'}
              />
            </Box>
          </>
        )}
      </CardContent>
      <Dialog open={fullscreen} onClose={handleCloseFullscreen} maxWidth="md" fullWidth>
        <DialogContent>
          <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
            bgcolor={isBlackBackground ? 'black' : 'white'}
          >
            <CardMedia
              sx={dialogImageStyle}
              component="img"
              image={displayUrl}
              title={title}
              onClick={handleCloseFullscreen}
              onLoad={handleImageLoad}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default StaticArtworkCard;

