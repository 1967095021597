import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Container } from '@mui/material';
import { Storage } from 'aws-amplify';

const VideoPlayer = ({ videoFileName }) => {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const url = await Storage.get(videoFileName, { level: 'public' });
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video', error);
      }
    };

    fetchVideoUrl();
  }, [videoFileName]);

  return (
    <Container>
      {videoUrl && <ReactPlayer url={videoUrl} controls width="100%" height="auto" />}
    </Container>
  );
};

export default VideoPlayer;
