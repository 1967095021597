export const artworksData = [
  {
    id: 1,
    imgUrl: '/assets/images/alice/PROCESSED_Alice Enchanted Journey.png',
    originalImageUrl: '/assets/images/alice/ORIGINAL_Alice Enchanted Journey.png',
    title: "Alice in Wonderland - Alice's Enchanted Journey",
    keywords: "Alice, enchanted forest, glowing mushrooms, talking animals, magical beams of light, aesthetic, sticker",
    description: 'Alice walking through an enchanted forest, surrounded by magical beams of light, talking animals, and glowing mushrooms. Perfect for those who love the whimsical and enchanting',
    prompt: "Alice walking through an enchanted forest, with glowing mushrooms, talking animals, and magical beams of light., aesthetic, sticker --s 100 --c 0 --q 1 --ar 1:1 --stop 100 --v 5.2",
    source_url: "dummyUrl",
    art_type: "STICKER",
  },
  {
    id: 2,
    imgUrl: '/assets/images/alice/ORIGINAL_Distorted Wonderland.png',
    originalImageUrl: '/assets/images/alice/ORIGINAL_Distorted Wonderland.png',
    title: "Cheshire Cat - Distorted Wonderland",
    keywords: "cheshire cat, alice in wonderland, creepy, surreal, beautiful landscape",
    description: 'A grinning Cheshire Cat whose smile extends unnaturally wide. The sky above is a mix of vibrant colors blending in disorienting patterns. The overall image creates a chilling and dreamlike atmosphere, capturing the eerie essence of Alice in Wonderland',
    prompt: "Alice in Wonderland, creepy, surreal, distorted clocks, talking flowers, wide-smiling Cheshire Cat, disorienting sky, aesthetic, standard --s 100 --c 0 --q 1 --ar 1:1 --stop 100 --v 5.2",
    source_url: "dummyUrl",
    art_type: "STANDARD",
  },
  {
    id: 4,
    imgUrl: '/assets/images/alice/ORIGINAL_Wonderland Landscape - Whirling Time.png',
    originalImageUrl: '/assets/images/alice/ORIGINAL_Wonderland Landscape - Whirling Time.png',
    title: "Wonderland Landscape - Whirling Time",
    keywords: "Wonderland landscape, topsy-turvy, upside-down trees, floating clocks, vibrant colors, dreamlike atmosphere",
    description: 'Immerse yourself in a whimsical wonderland landscape with vibrant colors, topsy-turvy upside-down trees, and floating clocks. A dreamlike atmosphere awaits',
    prompt: "Wonderland landscape, topsy-turvy, upside-down trees, floating clocks, vibrant colors, dreamlike atmosphere. --s 100 --c 0 --q 1 --ar 1:1 --stop 100 --v 5.2",
    source_url: "dummyUrl",
    art_type: "STANDARD",
  },
  {
    id: 3,
    imgUrl: '/assets/images/alice/PROCESSED_Cheshire Cat - Mysterious Melody.png',
    originalImageUrl: '/assets/images/alice/ORIGINAL_Cheshire Cat - Mysterious Melody.png',
    title: "Cheshire Cat - Playful Purrfection",
    keywords: "cheshire cat, sticker, Alice in Wonderland, fantasy, cute, cat lover, decoration, laptop decal, wall decal, car decal",
    description: 'Show your love for the mischievous Cheshire Cat with this adorable sticker. Perfect for cat lovers and fans of Alice in Wonderland',
    prompt: "cheshire cat, sticker --v 5.2",
    source_url: "dummyUrl",
    art_type: "STICKER",
  },
]
