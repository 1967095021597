export default [
    {
        "name": "EXPLORATION",
        "price": 5.0,
        "title": "Exploration - 5$",
        "bg_image": "/assets/images/wonderland_exploration.png",
        "tokens": 300,
        "youGet": [
            '300 AI Artworks',
            '150 Upscaled AI Artworks'
        ],
        "features": [
            '500 artworks',
            'Automated artwork generation',
            'Automated upscaling for 10 images',
            'Automated sticker cropping (beta version)',
            'Automatic artwork listings for your store',
            'Convenient Online Artwork Storage'
        ],
        "missingFeatures": [
            'Limited Availability'
        ],
        "comments": [],
        "enabled": true
    },
    {
        "name": "WONDERLAND",
        "price": 40.0,
        "title": "Wonderland - 40$",
        "bg_image": "/assets/images/wonderland_cat_0.png",
        "tokens": 4000,
        "youGet": [
            '4000 AI Artworks',
            '2000 Upscaled AI Artworks'
        ],
        "features": [
            '2000 high resolution artworks',
            'Automated artwork generation',
            'Automated upscaling',
            'Automated sticker cropping (beta)',
            'Automatic artwork listings for your store',
            'Convenient Online Artwork Storage'
        ],
        "missingFeatures": [],
        "comments": ['*you need your own Midjourney account'],
        "enabled": true
    },
    {
        "name": "MADNESS",
        "price": 80.0,
        "title": "Madness - 80$",
        "bg_image": "/assets/images/wonderland_red_queen.png",
        "tokens": 10000,
        "youGet": [
            '10000 AI Artworks',
            '5000 Upscaled AI Artworks'
        ],
        "features": [
            'Unlimited high resolution artworks',
            'Automated artwork generation',
            'Automated upscaling',
            'Automated sticker cropping (beta)',
            'Automatic artwork listings for your store',
            'Convenient Online Artwork Storage'
        ],
        "missingFeatures": [],
        "enabled": true
    },
];