export const getTokenCostsForUpscaling = (standardCounter, stickerCounter, isCropEnabled) => {
    const stickerCosts = isCropEnabled ? stickerCounter*2 : stickerCounter;
    return standardCounter + stickerCosts;
};

export const getTokenCostsForCreationPrompts = (clickedCounter) => {
    return clickedCounter
};

export const getTokenCostsForCreationExplore = (clickedCounter) => {
    return clickedCounter
};