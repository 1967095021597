import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import IconArrowBack from '@mui/icons-material/ArrowBack';
import IconArrowForward from '@mui/icons-material/ArrowForward';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { data } from './popular-course.data';

function CustomCarousel() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <Box sx={{ width: '100%', height: 'auto' }}>
      <Slider {...settings}>
        {data.map(item => (
          <img
            key={item.id}
            src={item.cover}
            alt={'Course ' + item.id}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        ))}
      </Slider>
    </Box>
  );
}

function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <IconButton
        className={className}
        onClick={onClick}
        sx={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          zIndex: 11,
          color: '#fff',
        }}
      >
        <IconArrowForward />
      </IconButton>
    );
}
  
function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <IconButton
        className={className}
        onClick={onClick}
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          zIndex: 11,
          color: '#fff',
        }}
      >
        <IconArrowBack />
      </IconButton>
    );
}
  

export default CustomCarousel;
