import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

const NicheDeleteDialog = ({ open, onClose, onDelete}) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Sure you want to delete?</Typography>
      </DialogTitle>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
        <IconButton onClick={() => {
            onDelete();
            onClose();
          }
        }>
          <DoneIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default NicheDeleteDialog;
