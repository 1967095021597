import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '../StateContext';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import { click } from '@testing-library/user-event/dist/click';
import { getTokenCostsForUpscaling } from '../services/tokenCalculator';

const UploadDialog = ({ open, artworkStage, onClose, handleUpscale, clickedCounter, selectedStickerCount}) => {

  const standardCount = clickedCounter - selectedStickerCount;
  const { tokens, setTokens } = useContext(StateContext);

  const [ tokenCosts, setTokenCosts ] = useState(0);

  const [isUpscaledStage, setIsUpscaledStage] = useState(artworkStage=='UPSCALED');

  const [scaleFactorOptions, setScaleFactorOptions] = useState(['2', '4', '8']);

  const [selectedStandardScaleFactor, setStandardSelectedScaleFactor] = useState('8');
  const [selectedStickerScaleFactor, setStickerSelectedScaleFactor] = useState('8');

  const popularResolutions = ['4500x4500', '4500x5400'];
  const [selectedStandardResolution, setSelectedStandardResolution] = useState(null);
  const [selectedStickerResolution, setSelectedStickerResolution] = useState(null);

  const initialWidth = 1024;
  const initialHeight = 1024;

  var scaleFactor = 1;
  // const [scaleFactor, setScaleFactor] = useState(1);
  const [displayedStandardWidth, setDisplayedStandardWidth] = useState(initialWidth);
  const [displayedStandardHeight, setDisplayedStandardHeight] = useState(initialHeight);

  const [selectedStandardWidth, setSelectedStandardWidth] = useState(initialWidth);
  const [selectedStandardHeight, setSelectedStandardHeight] = useState(initialHeight);

  // Standard
  const [displayedStickerWidth, setDisplayedStickerWidth] = useState(initialWidth);
  const [displayedStickerHeight, setDisplayedStickerHeight] = useState(initialHeight);

  const [selectedStickerWidth, setSelectedStickerWidth] = useState(initialWidth);
  const [selectedStickerHeight, setSelectedStickerHeight] = useState(initialHeight);

  const [isDoneDisabled, setIsDoneDisabled] = useState(false);

  useEffect(() => {
    setTokenCosts(getTokenCostsForUpscaling(standardCount, selectedStickerCount, true));
  }, [standardCount, selectedStickerCount ]);

  useEffect(() => {
    checkValidity();
  }, [tokens, tokenCosts, selectedStandardWidth, selectedStandardHeight, selectedStickerWidth, selectedStickerHeight]);

  const checkValidity = () => {

    const standardValidations = [
        1024 < selectedStandardWidth && selectedStandardWidth < 8192,
        1024 < selectedStandardHeight && selectedStandardHeight < 8192
    ];

    const stickerValidations = [
      1024 < selectedStickerWidth && selectedStickerWidth < 8192,
      1024 < selectedStickerHeight && selectedStickerHeight < 8192
    ];

    const validations = [];
    if (standardCount > 0) validations.push(...standardValidations);
    if (selectedStickerCount > 0) validations.push(...stickerValidations);

    if (tokens < tokenCosts || validations.some(val => !val)) {
        console.log('done disabled');
        setIsDoneDisabled(true);
    } else {
        setIsDoneDisabled(false);
    }
  };

  const handleStandardWidthChange = (event) => {
    const parsedNumber = parseInt(event.target.value, 10);
    if (isNaN(parsedNumber)){
      setDisplayedStandardWidth(0);
      setDisplayedStandardWidth(null);
    } else {
     if (0 <= parsedNumber && parsedNumber <= 8192) {
      setSelectedStandardWidth(parsedNumber);
      setDisplayedStandardWidth(parsedNumber);
     } 
    }
  };

  const handleStandardHeightChange = (event) => {
    const parsedNumber = parseInt(event.target.value, 10);
    if (isNaN(parsedNumber)){
      setSelectedStandardHeight(0);
      setDisplayedStandardHeight(null);
    } else {
     if (0 <= parsedNumber && parsedNumber <= 8192) {
      setDisplayedStandardHeight(parsedNumber);
      setSelectedStandardHeight(parsedNumber);
     } 
    }
  };

  const handleSelectedStandardResolution = (resString) => {
    console.log('resString: ', resString);
    // setSelectedResolution(resArray.join('x'));
    setSelectedStandardResolution(resString);
    const width = parseInt(resString.split('x')[0]);
    const height = parseInt(resString.split('x')[1]);
    console.log('width, height: ', width, height);
    setSelectedStandardHeight(height);
    setDisplayedStandardHeight(height);
    setSelectedStandardWidth(width);
    setDisplayedStandardWidth(width);
  }

  const handleStickerWidthChange = (event) => {
    const parsedNumber = parseInt(event.target.value, 10);
    if (isNaN(parsedNumber)){
      setSelectedStickerWidth(0);
      setDisplayedStickerWidth(null);
    } else {
     if (0 <= parsedNumber && parsedNumber <= 8192) {
      setSelectedStickerWidth(parsedNumber);
      setDisplayedStickerWidth(parsedNumber);
     } 
    }
  };

  const handleStickerHeightChange = (event) => {
    const parsedNumber = parseInt(event.target.value, 10);
    if (isNaN(parsedNumber)){
      setSelectedStickerHeight(0);
      setDisplayedStickerHeight(null);
    } else {
     if (0 <= parsedNumber && parsedNumber <= 8192) {
      setSelectedStickerHeight(parsedNumber);
      setDisplayedStickerHeight(parsedNumber);
     } 
    }
  };

  const handleSelectedStickerResolution = (resString) => {
    console.log('resString: ', resString);
    setSelectedStickerResolution(resString);
    const width = parseInt(resString.split('x')[0]);
    const height = parseInt(resString.split('x')[1]);
    console.log('width, height: ', width, height);
    setSelectedStickerHeight(height);
    setDisplayedStickerHeight(height);
    setSelectedStickerWidth(width);
    setDisplayedStickerWidth(width);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Upscale Options</Typography>
      </DialogTitle>
      <DialogContent>

        {standardCount > 0 && (
          <Box mt={4}>
            <Typography variant="paragraph">
              {"You selected " + standardCount + " STANDARD"}
            </Typography>
            <Box mt={2}>
              <FormControl fullWidth>
                <InputLabel id="excel-file-name-select-label">Popular Example Resolutions</InputLabel>
                <Select
                  labelId="excel-file-name-select-label"
                  id="excel-file-name-select"
                  value={selectedStandardResolution}
                  onChange={(e) => handleSelectedStandardResolution(e.target.value)}
                >
                  {popularResolutions.map((resolution) => (
                    <MenuItem key={resolution} value={resolution}>
                      {resolution}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Typography variant="paragraph">
              {"Upscale " + standardCount + " STANDARD for " +  standardCount + " Tokens"}
            </Typography>
            <Box mt={4}>
                <TextField
                    label="Width resolution"
                    type="number"
                    value={displayedStandardWidth}
                    onChange={handleStandardWidthChange}
                    sx={{ marginRight: 2 }}
                    inputProps={{
                      step: initialWidth,
                    }}
                />
                <TextField
                    label="Height resolution"
                    type="number"
                    value={displayedStandardHeight}
                    onChange={handleStandardHeightChange}
                    sx={{ marginRight: 2 }}
                    inputProps={{
                      step: initialHeight,
                    }}
                />
            </Box>
          </Box>
        )}

        {selectedStickerCount > 0 && (
          <Box mt={4}>
            <Typography variant="paragraph">
              {"You selected " + selectedStickerCount + " STICKER"}
            </Typography>
            <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel id="excel-file-name-select-label">Popular Example Resolutions</InputLabel>
              <Select
                labelId="excel-file-name-select-label"
                id="excel-file-name-select"
                value={selectedStickerResolution}
                onChange={(e) => handleSelectedStickerResolution(e.target.value)}
              >
                {popularResolutions.map((resolution) => (
                  <MenuItem key={resolution} value={resolution}>
                    {resolution}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
            <Typography variant="paragraph">
              {"Crop and Upscale " + selectedStickerCount + " Sticker for " +  selectedStickerCount*2 + " Tokens"}
            </Typography>
            <Box mt={4}>
              <TextField
                  label="Width resolution"
                  type="number"
                  value={displayedStickerWidth}
                  onChange={handleStickerWidthChange}
                  sx={{ marginRight: 2 }}
                  inputProps={{
                    step: initialWidth,
                  }}
              />
              <TextField
                  label="Height resolution"
                  type="number"
                  value={displayedStickerHeight}
                  onChange={handleStickerHeightChange}
                  sx={{ marginRight: 2 }}
                  inputProps={{
                    step: initialHeight,
                  }}
              />
            </Box>
          </Box>
        )}

        {(tokens < clickedCounter) && (
          <Typography variant="h6" color='orange'>
            You dont have enough tokens! Pay up :)
          </Typography>
        )}
        {(isDoneDisabled) && (
          <Typography variant="h6" color='orange'>
            Make sure width and height are over 1024 o.0
          </Typography>
        )}
        <Box mt={4}>
          <Typography variant="paragraph">
              {"Total Tokens: " + tokenCosts}
          </Typography>
        </Box>
        
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
        <IconButton onClick={() => {
            setTokens((prevTokens) => prevTokens - tokenCosts);
            handleUpscale([selectedStandardWidth, selectedStandardHeight], [selectedStickerWidth, selectedStickerHeight]);
            onClose();
          }
        } disabled={isDoneDisabled}>
          <DoneIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
