import { DataStore, Storage } from 'aws-amplify';
import { Theme } from '../models';

export const fetchThemes = async (userId) => {
  try {
    // const offset = currentPage * pageSize;
    const themes = await DataStore.query(Theme, (t) => t.userID.eq(userId));

    console.log('themes: ', themes);
    return themes;
  } catch (error) {
    console.log('Error fetching themes:', error);
    return [];
  }
};

export const createTheme = async (theme) => {
  try {
    const response = await DataStore.save(
      new Theme(theme)
    );
    return response;
  } catch (error){
    console.log('Error creating theme:', error);
    return null;
  }
};

export const updateTheme = async (updatedTheme) => {
  try {
    await DataStore.save(updatedTheme);
  } catch (error) {
    console.log('Error updating theme!: ', error);
  }
};

export const deleteTheme = async (nicheId) => {
  try {
      const deletedArtwork = await DataStore.delete(Theme, (t) => t.id.eq(nicheId));
      console.log('Theme deleted:', deletedArtwork);
      return deletedArtwork;
  } catch (error) {
      console.log('Error deleting Theme:', error);
      return null;
  }
};