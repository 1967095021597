export const getMetaUserId = () => {
    const env = process.env.REACT_APP_ENV

    let metaUserId;

    switch(env) {
        case 'dev':
            metaUserId = process.env.REACT_APP_DEV_META_USER_ID;
            break;
        case 'test':
            metaUserId = process.env.REACT_APP_TEST_META_USER_ID;
            break;
        case 'prod':
            metaUserId = process.env.REACT_APP_PROD_META_USER_ID;
            break;
        default:
            metaUserId = process.env.REACT_APP_DEV_META_USER_ID; // default to dev if not recognized
    }

    console.log('metaUserId: ', metaUserId);
    return metaUserId;
}

export const getMetaUserTasksId = () => {
    const env = process.env.REACT_APP_ENV

    let metaUserTasksId;

    switch(env) {
        case 'dev':
            metaUserTasksId = process.env.REACT_APP_DEV_META_USER_TASKS_ID;
            break;
        case 'test':
            metaUserTasksId = process.env.REACT_APP_TEST_META_USER_TASKS_ID;
            break;
        case 'prod':
            metaUserTasksId = process.env.REACT_APP_PROD_META_USER_TASKS_ID;
            break;
        default:
            metaUserTasksId = process.env.REACT_APP_DEV_META_USER_TASKS_ID; // default to dev if not recognized
    }

    console.log('metaUserTasksId: ', metaUserTasksId);
    return metaUserTasksId;
}