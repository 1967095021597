import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { AppBar, Toolbar, Typography, IconButton, Button, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { StateContext } from '../StateContext';
import { useTheme } from '@mui/material/styles';
import { Auth, DataStore, SortDirection } from 'aws-amplify';
import { User, UserTasks } from '../models';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Hub } from 'aws-amplify';
import AuthenticatorDialog from './AuthenticatorDialog';


import { getMetaUserId, getMetaUserTasksId } from '../services/userService';

import Logo from './Logo';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
}));

// We just want the Authenticator, not the extra UI
const LoginForm = withAuthenticator(() => null);

// const Header = ({ handleMenuClick }) => {
const Header = React.forwardRef(({ handleMenuClick }, ref) => {
  const { isLoggedIn, setIsLoggedIn, 
    tokens, setTokens, 
    userId, setUserId,
    userItem, setUserItem,
    userTasksItem, setUserTasksItem,
    metaUserItem, setMetaUserItem,
    metaUserTasksItem, setMetaUserTasksItem,
    isDemoUser, setIsDemoUser,
    headerLoginDialogOpen, setHeaderLoginDialogOpen
  } = useContext(StateContext);

  const [userTasksItemId, setUserTasksItemId] = useState(null);
  
  const theme = useTheme();

  const handleLogout = () => {
    Auth.signOut()
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch((err) => console.log(err));
  };

  const handleLoginClick = () => {
    setHeaderLoginDialogOpen(true);
  };

  const handleLoginClose = () => {
    setHeaderLoginDialogOpen(false);
  };
  
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('signed in');
          setIsLoggedIn(true);
          // Here you can also set the user ID if you want
          break;
        case 'signOut':
          console.log('signed out');
          setIsLoggedIn(false);
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    const fetchUserId = async () => {
      // if (!isLoggedIn){
        try {
          const user = await Auth.currentAuthenticatedUser();
          console.log('fetched use object: ', user);
          if (user) {
            const fetchedUserId = user.attributes.sub; // Get the user ID (primary key)
            setUserId(fetchedUserId); // Update the userId state
            console.log('Success to fetch user ID:', fetchedUserId);
            setIsLoggedIn(true);
          }
        } catch (error) {
          console.log('Failed to fetch user ID:', error);
        }
      // }
    };

    console.log('use effect in Header triggered!');
    fetchUserId(); // Fetch the user ID initially
  }, [isLoggedIn]);

  useEffect(() => {
    if (userItem?.membership === 'DEMO') {
      console.log('is Demo User!');
      setIsDemoUser(true);
    } else {
      console.log('is no Demo User!');
      setIsDemoUser(false);
    }
  }, [userItem]);

  useEffect(() => {
    let metaUserItemSubscription;
    let metaUserTasksItemSubscription;

    if (isDemoUser) {
      metaUserItemSubscription= DataStore.observeQuery(
        User,
        u => u.id.eq(getMetaUserId())
      ).subscribe(snapshot => {
        const { items, isSynced } = snapshot;
        if (items.length >= 0) {
          console.log('fetched META userItem: ', items[0], isSynced);
          setMetaUserItem(items[0]);
        }
      });

      metaUserTasksItemSubscription= DataStore.observeQuery(
        UserTasks,
        ut => ut.id.eq(getMetaUserTasksId())
      ).subscribe(snapshot => {
        const { items, isSynced } = snapshot;
        if (items.length >= 0) {
          console.log('fetched META userTasksItem: ', items[0], isSynced);
          setMetaUserTasksItem(items[0]);
          const originalMetaUserTasksItem = setMetaUserTasksItem(items[0]);
          if (originalMetaUserTasksItem) {
            const parsedMetaUserTasksItem = {
              ...originalMetaUserTasksItem,
              artworkCreation: originalMetaUserTasksItem.artworkCreation.map(item => typeof item === "string" ? JSON.parse(item) : item),
              artworkUpscaling: originalMetaUserTasksItem.artworkUpscaling.map(item => typeof item === "string" ? JSON.parse(item) : item),
              promptGeneration: originalMetaUserTasksItem.promptGeneration.map(item => typeof item === "string" ? JSON.parse(item) : item),
            };
            setMetaUserTasksItem(parsedMetaUserTasksItem);
          }
        }
      });
    }

    return () => {
      if (metaUserItemSubscription) {
        metaUserItemSubscription.unsubscribe();
      }
      if (metaUserTasksItemSubscription) {
        metaUserTasksItemSubscription.unsubscribe();
      }
    };
  }, [isDemoUser]);

  useEffect(() => {
    let subscription;
    let userTasksSubscription;

    if (isLoggedIn && userId) {

      subscription= DataStore.observeQuery(
        User,
        u => u.id.eq(userId)
      ).subscribe(snapshot => {
        const { items, isSynced } = snapshot;
        if (items.length >= 0) {
          console.log('fetched userItem: ', items, isSynced);
          const userItem = items[0];
          if (userItem) {
            setUserItem(userItem);
            console.log('EXEcute observeQuery: ', userItem.tokens);
            setTokens(userItem.tokens); // Update the tokens state
          }
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [isLoggedIn, userId]);

  useEffect(() => {
    const fetchInitialUserTasksItem = async () => {
        const tmpUserTasksItems = await DataStore.query(
            UserTasks,
            ut => ut.userTasksUserId.eq(userId)
        );
        console.log('tmpUserTasksItem: ', tmpUserTasksItems);
        if (tmpUserTasksItems.length > 0) {
          setUserTasksItemId(tmpUserTasksItems[0].id);
        }
    };
    if (isLoggedIn && userId) {
      fetchInitialUserTasksItem();
    }
  }, [isLoggedIn, userId]);

  useEffect(() => {
    let userTasksSubscription;

    if (isLoggedIn && userId && userTasksItemId) {
      console.log('usertasks item subscription: ', userTasksItem);
      userTasksSubscription = DataStore.observeQuery(
        UserTasks,
        ut => ut.id.eq(userTasksItemId)
        // ut => ut.id.eq('5b107d14-0a88-4f8c-b20c-e09ba5c3d4a3')
      ).subscribe(snapshot => {
        const { items, isSynced } = snapshot;
        if (items.length >= 0) {
          console.log('fetched userTasksItem: ', items, isSynced);
          const originalUserTasksItem = items[0];

          if (originalUserTasksItem) {
            const userTasksItem = {
              ...originalUserTasksItem,
              artworkCreation: originalUserTasksItem.artworkCreation.map(item => typeof item === "string" ? JSON.parse(item) : item),
              artworkUpscaling: originalUserTasksItem.artworkUpscaling.map(item => typeof item === "string" ? JSON.parse(item) : item),
              promptGeneration: originalUserTasksItem.promptGeneration.map(item => typeof item === "string" ? JSON.parse(item) : item),
            };
            setUserTasksItem(userTasksItem);
          }
        }
      });
    }

    return () => {
      if (userTasksSubscription) {
        userTasksSubscription.unsubscribe();
      }
    };
  }, [isLoggedIn, userId, userTasksItemId]);

  return (
    <StyledAppBar ref={ref} position="fixed">
    {/* <StyledAppBar ref={ref}> */}
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            onClick={handleMenuClick}
            edge="start"
            sx={{marginRight: theme.spacing(2), display: { md: 'none' }}}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Logo style={{ width: '70px', marginRight: '8px' }} />
          </Box>
          {isDemoUser && (
            <Typography variant="h6" noWrap sx={{ marginRight: '16px' }}>
              Demo
            </Typography>
          )}
          
        </Box>
        <nav>
          {isLoggedIn ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" noWrap sx={{ marginRight: '16px' }}>
                Tokens: {tokens}
              </Typography>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          ) : ( // replaces the LoginPage
            <React.Fragment>
              <Button color="inherit" onClick={handleLoginClick}>
                Login/Register
              </Button>
              <AuthenticatorDialog open={headerLoginDialogOpen} onClose={handleLoginClose} />
            </React.Fragment>
          )}
        </nav>
      </Toolbar>
    </StyledAppBar>
  );
});

export default Header;
