import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from './theme';
import { StateProvider } from './StateContext';

Amplify.configure(awsconfig);

const Main = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = getTheme(darkMode);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <StateProvider darkMode={darkMode} setDarkMode={setDarkMode}>
          <App />
        </StateProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);
reportWebVitals();
