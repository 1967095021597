import { DataStore, Storage } from 'aws-amplify';
import { Options } from '../models';

export const fetchOptions = async (optionName) => {
  try {
    // const offset = currentPage * pageSize;
    const options = await DataStore.query(Options, (o) => o.name.eq(optionName));
    console.log('options: ', optionName, options[0]);
    return options[0];
  } catch (error) {
    console.log('Error fetching options:', error);
    return [];
  }
};