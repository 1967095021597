import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import StaticArtworkCard from './StaticArtworkCard';

const ArtworkGrid = ({ artworks, areClicked, toggleIsClicked, handleEditArtwork }) => {
  const cardsPerRow = 3;

  const handleScroll = () => {
    console.log('handleScroll');
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  
    // Check if the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight) {
      // Perform the action to load the next page of artworks
      // loadNextPage();
      console.log('LOAD NEXT PAGE BCS OF SCROLLLLIIIINNNNNGUU');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!artworks || artworks.length === 0) {
    return (
      <Typography variant="h5" gutterBottom>
        No artworks available.
      </Typography>
    );
  }

  const rows = Math.ceil(artworks.length / cardsPerRow);

  return (
    <Grid container spacing={2}>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Grid container item xs={12} spacing={2} key={rowIndex}>
          {artworks
            .slice(rowIndex * cardsPerRow, (rowIndex + 1) * cardsPerRow)
            .map((artwork, index) => (
              <Grid item xs={4} key={artwork.id}>
                <StaticArtworkCard 
                    artwork={artwork} 
                    isClicked={areClicked[(rowIndex * cardsPerRow)+index]} 
                    toggleIsClicked={() => toggleIsClicked((rowIndex * cardsPerRow)+index)}
                    handleEditArtwork={handleEditArtwork}
                />
              </Grid>
            ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default ArtworkGrid;
