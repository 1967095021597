import React, { useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Box, List, ListItem, ListItemIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

function TokenPackageCard({ title, bgImage, tokens, youGet, features, missingFeatures, cardID, clickedCardID, handleCardClick, enabled }) {

  const handleClick = () => {
    if (enabled) {
      // If the card is already clicked, set the clickedCardID to null. Otherwise, set it to this card's ID.
      console.log('card was clicked, setting card_id: ', cardID,  cardID === clickedCardID ? null : cardID);
      handleCardClick(cardID === clickedCardID ? null : cardID);
    }
  };
  
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 345,
        cursor: 'pointer',
        border: cardID === clickedCardID ? '2px solid blue' : '1px solid gray',
        boxShadow: '0 0 5px 2px rgba(120, 120, 120, 0.5)',
      }}
      onClick={handleClick}
    >
      <CardMedia 
        component="img"
        sx={{ 
          height: 345,
          backgroundColor: 'white',
          opacity: enabled ? 1.0 : 0.5,
        }} 
        image={process.env.PUBLIC_URL + bgImage} 
        title={title} 
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>

        {/* <Typography gutterBottom variant="h6" component="div">
          {"You Get " + tokens + ' Tokens'}
        </Typography> */}

        <Typography gutterBottom variant="paragraph" component="div">
        {"You Get " + tokens + ' Tokens, Enough for:'}
        </Typography>

        <List>
          {youGet.map((detail, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                {detail}
              </ListItem>
              {(index !== youGet.length - 1) && <Typography variant="body1" sx={{marginLeft: '20px'}}>or</Typography>}
            </React.Fragment>
          ))}
        </List>
        
      </CardContent>
    </Card>
  );
}

export default TokenPackageCard;
