import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '../StateContext';
import { TextField, Box, Chip, Grid, Button, Collapse, CircularProgress, Typography, Autocomplete, Card } from '@mui/material';
import { requestCreateArtworksPrompts, requestGeneratePrompts } from '../services/restApiService';

const CreateArtworksPromptsMode = ({selectedNiche, isCreatingArtworks, canUserCreateArtworks, isDemoUser, isDemoCreateAvailable}) => {
  const { tokens, setTokens } = useContext(StateContext);

  const [rows, setRows] = useState([]);
  const [newPrompt, setNewPrompt] = useState('');
  const [newNumber, setNewNumber] = useState(1);

  const [totalNumber, setTotalNumber] = useState(0);
  const [totalNumberArtworks, setTotalNumberArtworks] = useState(0);

  const [isGenerateCollapsed, setIsGenerateCollapsed] = useState(true);
  const [isCreatable, setIsCreatable] = useState(false);

  const [isPayable, setIsPayable] = useState(true);

  const [isSendingCreationRequest, setIsSendingCreationRequest] = useState(false);

  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratable, setIsGeneratable] = useState(false);

  const [generateNumber, setGenerateNumber] = useState(4);
  const [selectedTopics, setSelectedTopics] = useState([]);

  useEffect(() => {
    const newTotalNumberArtworks = totalNumber*4;
    setTotalNumberArtworks(newTotalNumberArtworks);
    if (tokens < newTotalNumberArtworks) {
      isPayable && setIsPayable(false);
    } else {
      !isPayable && setIsPayable(true);
    }
  }, [totalNumber, tokens]);

  useEffect(() => {
    if (selectedNiche !== null) {
      console.log('selectedTopics: ', selectedTopics, (selectedTopics?.length > 0), isGeneratable);
      if (selectedTopics?.length > 0) {
        !isGeneratable && setIsGeneratable(true);
      } else {
        isGeneratable && setIsGeneratable(false);
      }
      !isGeneratable && (selectedTopics?.length > 0) && setIsGeneratable(true);
      if (rows.length !== 0) {
        setIsCreatable(true);
      } else {
        setIsCreatable(false);
      }
    } else {
      setIsGeneratable(false);
      setIsCreatable(false);
    }
  }, [selectedNiche, rows, selectedTopics]);

  const handleAddGeneratedRows = (newPrompts) => {
    let newRows = [];
    newPrompts.forEach((prompt) => {
      prompt.trim() !== '' && newRows.push({ prompt: prompt.trim(), repeat_n_times: 1 });
    });
    setRows((prevRows) => [...prevRows, ...newRows]);
    setTotalNumber((prevTotalNumber) => prevTotalNumber + newPrompts.length);
  };

  const handleAddRow = () => {
    if (newPrompt.trim() !== '' && newNumber !== '') {
      const newRow = { prompt: newPrompt.trim(), repeat_n_times: parseInt(newNumber, 10) };
      setRows([...rows, newRow]);
      setNewPrompt('');
      setNewNumber('1');
      setTotalNumber((prevTotalNumber) => prevTotalNumber + newRow.repeat_n_times);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((row, i) => {
      if (i !== index){
        return true;
      } else {
        setTotalNumber((prevTotalNumber) => prevTotalNumber - row.repeat_n_times);
        return false;
      }
    });
    setRows(updatedRows);
  };

  const handleEditNewNumber = (e, index) => {
    let newNumber = parseInt(e.target.value, 10);
    newNumber = isNaN(newNumber) ? 0 : newNumber;
    if (0 < newNumber && newNumber <= 100) {
      setNewNumber(newNumber);
    }
  };

  const handleEditNumber = (e, index) => {
    let newNumber = parseInt(e.target.value, 10);
    newNumber = isNaN(newNumber) ? 0 : newNumber;
    if (0 < newNumber && newNumber <= 100) {
      const updatedRows = [...rows];
      const oldNumber = updatedRows[index].repeat_n_times
      updatedRows[index].repeat_n_times = newNumber;
      setRows(updatedRows);
      setTotalNumber((prevTotalNumber) => prevTotalNumber + (newNumber - oldNumber));
    }
  };

  const handleEditGenerateNumber = (e) => {
    let newNumber = parseInt(e.target.value, 10);
    newNumber = isNaN(newNumber) ? 0 : newNumber;
    if (3 < newNumber && newNumber <= 16) {
      setGenerateNumber(newNumber);
    }
  };

  const handleGeneratePrompts = async () => {
    setIsGenerating(true);
    if (selectedNiche === null) {
      return;
    }
    const response = await requestGeneratePrompts(selectedNiche.id, generateNumber, selectedTopics);
    if (response) {
      const newPrompts = response.data.prompts;
      handleAddGeneratedRows(newPrompts);
    }
    setIsGenerating(false);
  };

  const handleCreateArtworks = async () => {
    setIsSendingCreationRequest(true);
    
    const response = await requestCreateArtworksPrompts(selectedNiche.id, rows);
    
    setIsSendingCreationRequest(false);
  };

  const handleClickPrompt = (index) => {
    const clickedRow = rows[index];
    console.log('clickedRow: ', clickedRow)
    setNewPrompt(clickedRow.prompt);
    setNewNumber(clickedRow.repeat_n_times.toString());
  };
  
  return (
    <Box>
      <Card style={{ marginBottom: '15px', marginTop: '20px' }}>
        <Box ml={5}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              Automated Prompt Generation
            </Typography>
          <Box mb={2} mt={5} width="100%">
            <Autocomplete
              multiple
              options={selectedNiche.topics}
              value={selectedTopics}
              onChange={(event, newValue) => {
                if (newValue.length <= 4) {
                  setSelectedTopics(newValue);
                } else {
                  // Perhaps show some error or warning to the user
                }
              }}
              freeSolo  // Allow user input not listed in dropdown
              getOptionLabel={(option) => option}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose or write up to 4 topics!"
                  variant="outlined"
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && event.target.value !== '' && selectedTopics.length < 4) {
                      setSelectedTopics([...selectedTopics, event.target.value]);
                      event.target.value = ''; 
                      event.preventDefault(); 
                    }
                  }}
                />
              )}
            />
            

          </ Box>

          <Box mb={2} mt={5} display="flex" alignItems="center">
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleGeneratePrompts} 
              disabled={isGenerating || !isGeneratable || (!canUserCreateArtworks && !isDemoCreateAvailable)} 
              sx={{ marginRight: 2 }} // Add margin to the right of the button
            >
              {isGenerating ? <CircularProgress size={24} color="secondary"/> : 'Generate ' + generateNumber + ' Random Prompts'}
            </Button>

            <TextField
              label="Generate 4-16 Prompts"
              type="number"
              value={generateNumber}
              onChange={handleEditGenerateNumber}
              size="small" // Make the TextField smaller
            />
          </Box>
        </Box>
      </Card>
        
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Write prompt yourself"
            value={newPrompt}
            onChange={(e) => setNewPrompt(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleAddRow();
              }
            }}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Run how often? (1-100)"
            type="number"
            value={newNumber}
            onChange={handleEditNewNumber}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleAddRow();
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" onClick={handleAddRow}>Add</Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={2}>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              <Grid item xs={8}>
                <Chip
                  label={row.prompt}
                  onDelete={() => handleRemoveRow(index)}
                  onClick={() => handleClickPrompt(index)}
                  sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  type="number"
                  value={row.repeat_n_times}
                  onChange={(e) => handleEditNumber(e, index)}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={() => {
          setTokens((prevTokens) => prevTokens - totalNumberArtworks);
          handleCreateArtworks();
        }} disabled={!isCreatable || isCreatingArtworks || (!isDemoUser && !canUserCreateArtworks) || !isPayable || (isDemoUser && !isDemoCreateAvailable)}>
          {isSendingCreationRequest ? <CircularProgress size={24} color="secondary"/> : 'Create ' + totalNumberArtworks + ' Artworks'}
        </Button>
        {!isPayable && (
          <Typography variant="h6" color='orange'>
            You dont have enough tokens! Pay up :)
          </Typography>
        )}
      </Box>
      {isDemoUser && (
        <Box mt={2}>
        {isDemoCreateAvailable ?  
          <Typography variant="h6" color='green'>
            Demo Image Generation is available !
          </Typography>
        : 
          <Typography variant="h6" color='orange'>
            Other free loaders are occupying the Demo, check in later!
          </Typography>
        }
      </Box>
      )}
    </Box>
  );
};

export default CreateArtworksPromptsMode;
