import React, { useState, useContext, useEffect } from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { StateContext } from '../StateContext';
import { TextField, Box, Autocomplete, Tabs, Tab, Typography, Button, CircularProgress } from '@mui/material';
import CreateArtworksPromptsMode from '../components/CreateArtworksPromptsMode';
import CreateArtworksExploreMode from '../components/CreateArtworksExploreMode';
import { DataStore, SortDirection, Auth} from 'aws-amplify';
import { Theme } from '../models';
import { getAvailableCreationAccounts } from '../services/userTasksService';
import { requestStopArtworkCreation } from '../services/restApiService';

const CreateArtworksPage = () => {
  const { isLoggedIn, userId, 
    userTasksItem, userItem, 
    isDemoUser, metaUserItem, metaUserTasksItem 
  } = useContext(StateContext);

  const [isDemoCreateAvailable, setIsDemoCreateAvailable] = useState(false);

  const [niches, setNiches] = useState([]);
  const [selectedNiche, setSelectedNiche] = useState(null);

  const [isCreatingArtworks, setIsCreatingArtworks] = useState(false);
  const [isStoppingArtworkCreation, setIsStoppingArtworkCreation] = useState(false);

  const [canUserCreateArtworks, setCanUserCreateArtworks] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const [tokenCosts, setTokenCosts ] = useState(0);

  useEffect(() => {
    if (isDemoUser && metaUserItem && metaUserTasksItem) {
      const availableCreationAccounts = getAvailableCreationAccounts(metaUserItem, metaUserTasksItem);
      if (Object.keys(availableCreationAccounts).length > 0) {
        setIsDemoCreateAvailable(true);
      } 
    } else {
      setIsDemoCreateAvailable(false);
    }
  }, [isDemoUser, metaUserItem, metaUserTasksItem]);

  useEffect(() => {
    setCanUserCreateArtworks(hasUserImageAIAccess(userItem));
  }, [userItem]);

  useEffect(() => {
    if (userTasksItem && userTasksItem.artworkCreation && Array.isArray(userTasksItem.artworkCreation)) {
      if (userTasksItem.artworkCreation.length > 0) {
        let hasRunningTasks = false; // Flag to check if any running tasks found
        userTasksItem.artworkCreation.forEach((runningTask) => {
          console.log('iter runningTask: ', runningTask);
          if (runningTask.instruction_type && runningTask.instruction_type !== 'STOP') {
            console.log('found running artworkCreation tasks!!');
            hasRunningTasks = true; // Set the flag to true if any running task is found
          }
        });
        // Check if no running tasks are found, then setIsCreatingArtworks(false)
        if (hasRunningTasks) {
          !isCreatingArtworks && setIsCreatingArtworks(true);
        } else {
          isCreatingArtworks && setIsCreatingArtworks(false);
          isStoppingArtworkCreation && setIsStoppingArtworkCreation(false);
        }
      } else {
        // If artworkCreation is an empty array, set setIsCreatingArtworks(false)
        isCreatingArtworks && setIsCreatingArtworks(false);
        isStoppingArtworkCreation && setIsStoppingArtworkCreation(false); //TODO ask for task_is soon
      }
    } else {
      // If userTasksItem or artworkCreation is not present or not an array, set setIsCreatingArtworks(false)
      isCreatingArtworks && setIsCreatingArtworks(false);
      isStoppingArtworkCreation && setIsStoppingArtworkCreation(false);
    }
  }, [userTasksItem]);  

  useEffect(() => {
    let subscription;

    const subscribeToThemes = async () => {
      try {
        subscription = DataStore.observeQuery(
          Theme,
          t => t.userID.eq(userId), {
            sort: s => s.createdAt(SortDirection.ASCENDING)
          }
        ).subscribe(snapshot => {
          const { items, isSynced } = snapshot;
          console.log(`[Snapshot] themes item count: ${items.length}, isSynced: ${isSynced} `, items);
          setNiches(items);
        });
      } catch (error) {
        console.error('Failed to fetch initial artworks:', error);
      }
    };

    if (isLoggedIn && userId) {
      subscribeToThemes();
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [isLoggedIn, userId]);

  const handleNicheSelect = (event, value) => {
    console.log('selected item: ', value);
    setSelectedNiche(value);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const hasUserImageAIAccess = (userItem) => {
    const hasAccounts = userItem?.credentials?.midj?.accounts && Object.keys(userItem.credentials.midj.accounts).length > 0;
    return hasAccounts;
  };

  const handleSendStopRequest = async () => {
    setIsStoppingArtworkCreation(true);
    await requestStopArtworkCreation();
  };

  return (
    <Box sx={{ marginTop: '30px', marginBottom: '30px'}}>
      <Typography variant="h3" gutterBottom>
        Create Artworks on Auto-Pilot
      </Typography>
      <Box mb={2}>
        <Autocomplete
          options={niches}
          value={selectedNiche}
          onChange={handleNicheSelect}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose your Niche!"
              variant="outlined"
              autoComplete="off"
            />
          )}
        />
      </Box>
      {(!isDemoUser && !canUserCreateArtworks) && (
        <Typography variant="paragraph" gutterBottom color="orange">
          Ooops, seems like you haven't connected your Image AI yet! Make sure you connected your GPT and Midjourney access in the Account Page.
        </Typography>
      )}

      {selectedNiche && (canUserCreateArtworks || isDemoCreateAvailable) && (
        <Box >  
        <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="Tabs navigation">
          <Tab label="Prompts" />
          <Tab label="Explore" />
        </Tabs>
        <Box mt={0}>
          {selectedTab === 0 && <CreateArtworksPromptsMode selectedNiche={selectedNiche} isCreatingArtworks={isCreatingArtworks} canUserCreateArtworks={canUserCreateArtworks} isDemoUser={isDemoUser} isDemoCreateAvailable={isDemoCreateAvailable}/>}
          {selectedTab === 1 && <CreateArtworksExploreMode selectedNiche={selectedNiche} isCreatingArtworks={isCreatingArtworks} canUserCreateArtworks={canUserCreateArtworks} isDemoUser={isDemoUser} isDemoCreateAvailable={isDemoCreateAvailable}/>}
        </Box>
      </ Box>
      )}
      
      {!selectedNiche && (
        <Typography variant="paragraph" gutterBottom>
          ...Select your Niche first!
        </Typography>
      )}

      {(isCreatingArtworks || isStoppingArtworkCreation) && (
        <Box mt={10} display="block">
          {/* <Typography variant="paragraph" gutterBottom>
            {isCreatingArtworks ? '*Mr. PodPipe is already creating images for you! You can stop it here for another try!' : 'Got it! May take up to a few minutes to stop the process...'}
          </Typography> */}
          {isCreatingArtworks && !isStoppingArtworkCreation && (
            <Typography variant="paragraph" gutterBottom>
              *Mr. PodPipe is already creating images for you! You can stop it here for another try!
            </Typography>
          )}
          {isStoppingArtworkCreation && (
            <Typography variant="paragraph" gutterBottom>
              Kay, stopping artwork creation. Mr. PodPipe will finish the current artworks and call it a day! May take a few minutes...
            </Typography>
          )}
        </Box>
      )}
      {(isCreatingArtworks || isStoppingArtworkCreation) && (
        <Button variant="contained" color="secondary" onClick={handleSendStopRequest} disabled={isStoppingArtworkCreation} sx={{ marginTop: 2 }}>
          {isStoppingArtworkCreation ? <CircularProgress size={24} color="primary" /> : 'Stop Artwork Creation'}
        </Button>
      )}
    </Box>
  );
};

export default withAuthenticator(CreateArtworksPage);
