import React, {useState, useEffect} from 'react';

import { Typography, Card, Box, TextField, Button, Snackbar, CircularProgress, Alert, Link, Divider  } from '@mui/material';
import VideoPlayer from '../components/Video';
import { requestSaveCredentials } from '../services/restApiService';


const TutorialsPage = () => {

  return (
    <Box sx={{ marginTop: '30px', marginBottom: '30px'}}>
      <Box sx={{
        marginBottom: '24px'
      }}>
        <Typography variant="h3" gutterBottom>
          PodPipe Tutorials
        </Typography>
        <Typography variant="h5" gutterBottom>
          All you need to know to get ready to automate your PrintOnDemand workflow
        </Typography>

        <Box sx={{ marginTop: '10px', marginBottom: '0px'}}>
          <Card >
            <Box sx={{ marginTop: '10px', marginBottom: '10px'}}>
              <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              After these steps you will be able to:
              </Typography>
              <Typography component="ol" sx={{
                  marginTop: '10px',
                }}>
                  <li>Generate artworks on auto-pilot</li>
                  <li>Get out of the box artwork listings with title, tags and description</li>
                  <li>Generate sticker artworks, automatically cropped for you (experimental stage)</li>
                  <li>Use bulk upscaling for your approved Artworks</li>
                  <li>Organize your generated artworks in the online Gallery</li>
                  <li>Upload your artworks seamlessly using third party tools</li>
              </Typography>
            </ Box>
          </ Card>
        </Box>

        <Box sx={{ marginTop: '10px', marginBottom: '0px'}}>
          <Card >
            <Box sx={{ marginTop: '10px', marginBottom: '10px'}}>
              <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                Prerequisites after Free-Trial in a nutshell:
              </Typography>
              <Typography component="ol" sx={{
                  marginTop: '10px',
                }}>
                  <li>You need your own Midjourney Pro subscription with enough fast hours</li>
                  <li>You need your own OpenAI API Key for chatGPT driven Listing generation</li>
                  <li>Temporarily: Up until around 15th September 2023, you will need your own free Vectorizer API Key if you want to upscale Sticker Artworks. Afterwards it will be obsolete.</li>
                  <li>* How to get those accounts is explained in the tutorials! Can take up to 25 minutes to setup!</li>
              </Typography>
            </ Box>
          </ Card>
        </Box>
      </Box>

      <Box sx={{
        marginTop: '30px',
        marginBottom: '24px'
      }}>
        <Box sx={{ marginTop: '10px', marginBottom: '0px'}}>
          <Card >
            <Box sx={{ marginTop: '10px', marginBottom: '10px'}}>
              <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
                PodPipe in a Nutshell
              </Typography>
              <Typography variant="paragraph" gutterBottom>
              PodPipe uses the <strong>Midjourney</strong> AI to create the images and <strong>ChatGPT</strong> for creating the Listings and if you want, the Midjourney prompts as well. You can then upscale the artworks you like, download them and easily upload them to your store either manually or use popular upload tools like LazyMerch, FlyingUploads or Ninja Automation to do the upload for you with the Excel Sheet provided by PodPipe. For the latter you will only need to drag the exported excel file in that tool and you are ready to go.
              </Typography>
              <Typography component="ul" sx={{
                  marginTop: '10px',
                }}>
                  <li>
                    <Box sx={{ width: '100%', marginBottom: '30px'}}>
                      <Typography variant="h6" gutterBottom>
                        Part 1: Overview and Artwork Creation
                      </Typography>
                      <Box sx={{marginTop: '10px'}}>
                        <VideoPlayer videoFileName='videos/IntroTutorial1.mp4'></VideoPlayer>
                      </Box>
                    </Box>
                  </li>
                  <li>
                    <Box sx={{ width: '100%', marginBottom: '30px'}}>
                      <Typography variant="h6" gutterBottom>
                      Part 2: Sticker Cropping and Upscaling
                      </Typography>
                      <Box sx={{marginTop: '10px'}}>
                        <VideoPlayer videoFileName='videos/IntroTutorial2.mp4'></VideoPlayer>
                      </Box>
                    </Box>
                  </li>
                  <li>
                    <Box sx={{ width: '100%', marginBottom: '30px'}}>
                      <Typography variant="h6" gutterBottom>
                        Part 3: Exporting and Upload
                      </Typography>
                      <Box sx={{marginTop: '10px'}}>
                        <VideoPlayer videoFileName='videos/IntroTutorial3.mp4'></VideoPlayer>
                      </Box>
                    </Box>
                  </li>
              </Typography>
            </ Box>
          </ Card>
        </Box>
      </Box>

    </Box>
  );
};

export default TutorialsPage;
