import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from 'react-slick';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconArrowBack from '@mui/icons-material/ArrowBack';
import IconArrowForward from '@mui/icons-material/ArrowForward';

import { data } from './popular-course.data';
import { artworksData } from './demoArtworksData';
import DemoArtworkCard from './DemoArtworkCard';


const SliderArrow = function(props) {
    const { onClick, type, className } = props;

    const styles = {
        backgroundColor: 'background.paper',
        color: 'primary.main',
        '&:hover': { 
            backgroundColor: 'primary.main', 
            color: 'primary.contrastText' 
        },
        bottom: { 
            xs: '-70px !important', 
            md: '-28px !important' 
        },
        left: 'unset !important',
        right: type === 'prev' ? '60px !important' : '0 !important',
        zIndex: 10,
        boxShadow: 1,
    };

    return React.createElement(
        IconButton,
        {
            sx: styles,
            disableRipple: true,
            color: "inherit",
            onClick: onClick,
            className: className
        },
        type === 'next' ? React.createElement(IconArrowForward, { sx: { fontSize: 22 } }) : React.createElement(IconArrowBack, { sx: { fontSize: 22 } })
    );
};

const StyledDots = styled('ul')(({ theme }) => ({
    '&.slick-dots': {
      position: 'absolute',
      left: 0,
      bottom: -20,
      paddingLeft: theme.spacing(1),
      textAlign: 'left',
      '& li': {
        marginRight: theme.spacing(2),
        '&>div': {
          backgroundColor: 'white', // Add the color you want for inactive dots here.
        },
        '&.slick-active>div': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  }))

const HomeExampleArtworks = function() {
    const { breakpoints } = useTheme();
    const matchMobileView = useMediaQuery(breakpoints.down('md'));
  
    const sliderConfig = {
      infinite: true,
      autoplay: true,
      speed: 300,
      slidesToShow: matchMobileView ? 1 : 3,
      slidesToScroll: 1,
      // prevArrow: React.createElement(SliderArrow, { type: "prev" }),
      // nextArrow: React.createElement(SliderArrow, { type: "next" }),
      dots: true,
      appendDots: function(dots) {
        return React.createElement(StyledDots, null, dots);
      },
      customPaging: function() {
        return React.createElement(Box, {
          sx: {
            height: 8,
            width: 30,
            // backgroundColor: 'background.homeDivider',
            backgroundColor: '#F3F5F5',
            display: 'inline-block',
            borderRadius: 4
          }
        });
      }
    };
  
    return React.createElement(
      Box,
      {
        id: "exampleArtworks",
        sx: {
          pt: {
            xs: 6,
            md: 8
          },
          pb: 14,
          backgroundColor: 'background.homeDivider'
        }
      },
      React.createElement(
        Container,
        { maxWidth: "lg" },
        React.createElement(
          Grid,
          { container: true, spacing: 2 },
          React.createElement(
            Grid,
            { item: true, xs: 12, md: 2 },
            React.createElement(
              Box,
              {
                sx: {
                  height: '100%',
                  width: { xs: '100%', md: '90%' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'flex-start' }
                }
              },
              React.createElement(
                Typography,
                { 
                  variant: "h1", 
                  sx: { mt: { xs: 0, md: -5 }, fontSize: { xs: 30, md: 48 } }
                },
                "Example Results"
              )
            )
          ),
          React.createElement(
            Grid,
            { item: true, xs: 12, md: 10 },
            React.createElement(
              Slider,
              sliderConfig,
              artworksData.map(function(item) {
                // return React.createElement(DemoArtworkCard, { key: String(item.id), artwork: item });
                return (
                  <Box key={String(item.id)} sx={{ margin: '0 8px' }}>
                    <DemoArtworkCard artwork={item} />
                  </Box>
                );
              })
            )
          )
        )
      )
    );
};
  

export default HomeExampleArtworks;
