import React, { useContext } from "react";
import { NavLink } from 'react-router-dom';
import { Drawer, Toolbar, List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import ReviewIcon from '@mui/icons-material/RateReview';
import DrawIcon from '@mui/icons-material/Draw';
import CollectionsIcon from '@mui/icons-material/Collections';
import MapIcon from '@mui/icons-material/Map';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import StoresIcon from '@mui/icons-material/Storefront';
import NichesIcon from '@mui/icons-material/Category';
import AccountIcon from '@mui/icons-material/AccountCircle';
import GuideIcon from '@mui/icons-material/Book';
import CommunityIcon from '@mui/icons-material/People';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import QuizIcon from '@mui/icons-material/Quiz';
import ForumIcon from '@mui/icons-material/Forum';

import { styled, useTheme } from '@mui/material';

import { StateContext } from '../StateContext';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.drawerWidth,
  '& .MuiBackdrop-root': {
    display: 'none',
  },
}));

const SideNavBar = () => {
  const theme = useTheme();
  const { open, isLoggedIn } = useContext(StateContext);

  return (
    <StyledDrawer
      variant="temporary"
      open={open}
      transitionDuration={{
        enter: theme.transitionDuration,
        exit: theme.transitionDuration,
      }}
      PaperProps={{ elevation: 9 }}
      disableEnforceFocus={true}
    >
      <Toolbar />
      <div>
        <List>
          <ListItemButton component={NavLink} to="/" end>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton component={NavLink} to="/tutorials" end>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Guide" />
          </ListItemButton>
          <ListItemButton component={NavLink} to="/faq" end>
            <ListItemIcon>
              <QuizIcon />
            </ListItemIcon>
            <ListItemText primary="FAQ" />
          </ListItemButton>
          <ListItemButton component={NavLink} to="/community" end>
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="Community" />
          </ListItemButton>

          <Divider />

          {isLoggedIn && (
            <div>
              <ListItemButton component={NavLink} to="/niches">
                <ListItemIcon>
                  <NichesIcon />
                </ListItemIcon>
                <ListItemText primary="Niches" />
              </ListItemButton>
              <ListItemButton component={NavLink} to="/create">
                <ListItemIcon>
                  <DrawIcon />
                </ListItemIcon>
                <ListItemText primary="Create" />
              </ListItemButton>

              <ListItemButton component={NavLink} to="/gallery">
                <ListItemIcon>
                  <CollectionsIcon />
                </ListItemIcon>
                <ListItemText primary="Gallery" />
              </ListItemButton>

              <Divider />

              <ListItemButton component={NavLink} to="/account">
                <ListItemIcon>
                  <AccountIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItemButton>
              <ListItemButton component={NavLink} to="/tokens">
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Tokens" />
              </ListItemButton>
            </div>
          )}
        </List>
      </div>
    </StyledDrawer>
  );
};

export default SideNavBar;
