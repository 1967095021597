export default {
    'artType': 'This controls the image generation and editing process. In STICKER mode the image will be cropped to to create a usable sticker without background. The drawback is that some images will be damaged in the process of cropping. The STANDARD mode does no editing of the image itself.',
    'topic': 'A topic serves as starting point for ChatGPT to get creative about the prompt writings. If you select more than 1 topic, than a random one will be drawn and used for the next batch of prompts.',
    'aestheticStyle': 'Describes an artwork style. Will be added to the Midjourney prompt. If multiple styles are selected, a random one will drawn.',
    'aestheticTheme': 'Describes an artwork theme such as cyberpunk. The word will be added directly to the Midjourney prompt. If multiple styles are selected, a random one will drawn.',
    'aestheticMood': 'Describes the mood of an artwork. Will be added to the Midjourney prompt. If multiple moods are selected, a random one will drawn.',
    'aestheticColoring': 'Adds a coloring phrase to the Midjourney prompt. If multiple colorings are selected, a random one will drawn.',
    'aestheticLens': 'Is added to the Midjourney prompt and describes a lens effect for the resulting images. If multiple lenses are selected, a random one will drawn.',
    'aestheticPose': 'Is added to the Midjourney prompt and describes the pose of character in the resulting images. Probably only useful if topics contain at least one person or character. If multiple poses are selected, a random one will drawn.',
    'midjourneyVersion': 'This parameter controls, which Midjourney version will be used to create the images. If multiple versions are selected, a random one will drawn.',
    'midjourneyStylize': 'This parameter controls the extend of artistic coloring and composition. The higher the value the more artistic.',
    'midjourneyChaos': 'This parameter controls how much more unusual and unexpected results will be. The higher the more chaotic',
    'midjourneyQuality': 'This parameter controls the resulting image quality in terms of detail. lower quality images are faster in production but might also be preferable depending on the artwork',
    'midjourneyStop': 'This parameter controls at what stage during the image production the process should stop. An early stop will result in more blurry images',
    'midjourneyStyle': 'Controls the overall aesthetic. Midjourney versions have different modes they can operate in. For the versions 5.1 and 5.2 you can only specify raw or leave it to the original default mode. The raw style gives more control over the result and is for advanced users who know what to specify in the prompt.',
    'midjourneyStyleNiji': 'Only used if Niji Version is selected in the Versions section. Controls the overall aesthetic. The Niji version has different modes it can operate in.',
    'midjourneyTile': 'The tile parameter will turn the artwork into a seamless pattern. The selected percentage controls how likely the created images will be a seamless pattern',
    'midjourneyAspectRatio': 'The aspect ratio gives you control over the width and height dimension. For example 1:1 will be the standard quadratic format, where as 4:1 might be a typical banner format. Use whole numbers only.',
    'midjourneyNo': 'The --no parameter gives you influence on what concepts should not appear in the resulting image. For example "--no tree" might avoid Midjourney to draw trees or at least fewer trees. There is no guarantee that it works.'
};