import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Typography, Snackbar, Alert } from '@mui/material';
import { requestContact } from '../services/restApiService';

const CommunityPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        message: ''
    });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await requestContact(formData.email, formData.message);
            console.log('contact request response:\n', response);

            if (response.status === 200) {
                let message = 'Message sent successfully! teehe cant extract message'
                try {
                    message = response.data.message
                } catch {
                    console.log('failure getting message from response: ', response)
                }
                setSnackbarMessage(message);
                setSnackbarSeverity('success');
                setFormData({ email: '', message: '' });
                setShowSnackbar(true);
            } else {
                setSnackbarMessage('Error sending message.');
                setSnackbarSeverity('error');
                setShowSnackbar(true);
            }
        } catch (error) {
            setSnackbarMessage('Error sending message.');
            setSnackbarSeverity('error');
            setShowSnackbar(true);
            console.error("There was an error sending the message", error);
        }
    }

    return (
        <Box sx={{marginTop: '30px', marginBottom: '30px', marginRight: '30px', marginLeft: '30px'}}>
            <Typography variant="h4" component="h2" gutterBottom>
                Join the Community
            </Typography>
            <Typography variant="body1" component="h2" gutterBottom>
                We will soon start a Discord Server for our community. For now please just use the good old contact form :)
            </Typography>
            <Box sx={{marginTop: '30px'}}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Message"
                                name="message"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleChange}
                                required
                                inputProps={{
                                    maxLength: 2000
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
                <Alert severity={snackbarSeverity} onClose={() => setShowSnackbar(false)}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default CommunityPage;
