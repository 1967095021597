import React, {useState, useEffect} from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Typography, Box, TextField, Button, Snackbar, CircularProgress, Alert, Link  } from '@mui/material';
import VideoPlayer from '../components/Video';
import { requestSaveCredentials } from '../services/restApiService';


const AccountPage = () => {
  const [midjServerId, setMidjServerId] = useState('');
  const [midjChannelId, setMidjChannelId] = useState('');
  const [midjUserToken, setMidjUserToken] = useState('');
  const [midjSubmittable, setMidjSubmittable] = useState(false);

  const [vectorizerApiKey, setVectorizerApiKey] = useState('');
  const [vectorizerSubmittable, setVectorizerSubmittable] = useState(false);

  const [openAIApiKey, setOpenAIApiKey] = useState('');
  const [openAISubmittable, setOpenAISubmittable] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (midjServerId.length > 10 && midjChannelId.length > 10  && midjUserToken.length > 10) {
      !midjSubmittable && setMidjSubmittable(true);
    } else {
      midjSubmittable && setMidjSubmittable(false);
    }
  }, [midjServerId, midjChannelId, midjUserToken])

  useEffect(() => {
    if (openAIApiKey.length > 10) {
      !openAISubmittable && setOpenAISubmittable(true);
    }else {
      openAISubmittable && setOpenAISubmittable(false);
    }
  }, [openAIApiKey])

  useEffect(() => {
    if (vectorizerApiKey.length > 10) {
      !vectorizerSubmittable && setVectorizerSubmittable(true);
    }else {
      vectorizerSubmittable && setVectorizerSubmittable(false);
    }
  }, [vectorizerApiKey])

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleMidjServerIdChange = (event) => {
    const value = event.target.value;
    const parsedNumber = parseInt(value);
    if (!isNaN(parsedNumber)){
      setMidjServerId(event.target.value);
    } else {
      setMidjServerId('');
    }
  };

  const handleMidjChannelIdChange = (event) => {
    const value = event.target.value;
    const parsedNumber = parseInt(value);
    if (!isNaN(parsedNumber)){
      setMidjChannelId(event.target.value);
    }else {
      setMidjServerId('');
    }
  };

  const handleMidjUserTokenChange = (event) => {
    setMidjUserToken(event.target.value.replace(/["']/g, ''));
  };

  const handleVectorizerApiKeyChange = (event) => {
    setVectorizerApiKey(event.target.value.replace(/["']/g, ''));
  };

  const handleOpenAIApiKeyChange = (event) => {
    setOpenAIApiKey(event.target.value.replace(/["']/g, ''));
  };

  const makeSecretManagerPostRequest = async (credential_type,  credentials) => {
  
    try {
      const response = await requestSaveCredentials(credential_type,  credentials);

      console.log('API response in makeSecretManagerPostRequest:', response);
      setSnackbarMessage(response.data.message);
      if (response.status === 200) {
        // Set success message and severity
        setSnackbarSeverity('success');
      } else {
        // Set error message and severity
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('API request failed:', error);
      setSnackbarMessage('Error message');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false); 
      setShowSnackbar(true);
    }
  };
  
  const handleSubmitMidjAccess = async (event) => {
    event.preventDefault(); // Prevent form submission
  
    // Add loading state
    setLoading(true);

    const credential_type = 'midj';

    const credentials = {
      'user_token': midjUserToken,
      'server_id': midjServerId,
      'channel_id': midjChannelId
    };

    makeSecretManagerPostRequest(credential_type, credentials);

    setMidjServerId('');
    setMidjChannelId('');
    setMidjUserToken('');
  };

  const handleSubmitVectorizerApiKey = async (event) => {
    event.preventDefault(); // Prevent form submission
  
    // Add loading state
    setLoading(true);

    const credential_type = 'vectorizer';

    const credentials = {
      'Authorization': vectorizerApiKey
    };
  
    makeSecretManagerPostRequest(credential_type,  credentials);

    setVectorizerApiKey('');
  };

  const handleSubmitOpenAIApiKey = async (event) => {
    event.preventDefault(); // Prevent form submission
  
    // Add loading state
    setLoading(true);

    const credential_type = 'openai';

    const credentials = {
      'API_KEY': openAIApiKey
    };
  
    makeSecretManagerPostRequest(credential_type,  credentials);

    setOpenAIApiKey('');
  };

  return (
    <Box sx={{ marginTop: '30px', marginBottom: '30px'}}>
      <Box sx={{
        marginBottom: '24px'
      }}>
        <Typography variant="h3" gutterBottom>
          Account Settings
        </Typography>
        <Typography variant="paragraph" gutterBottom>
          Fill out or edit information and get ready for art production!
        </Typography>
      </Box>
      <Box sx={{
        marginBottom: '40px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' // Customize the shadow properties here
      }}>
        <Box sx={{
          marginBottom: '20px'
        }}>
          <Typography variant="h4" gutterBottom>
            Midjourney Access Credentials
          </Typography>
          <Typography variant="paragraph" gutterBottom>
            In order to take over the image creation for you on your own Midjourney account, you need to submit your access credentials. The process can be a bit tricky, so pls check the video below. It will take about 5 minutes. Here is a summary of the video:
          </Typography>
          <Typography component="ol" sx={{
            marginTop: '10px',
          }}>
            <li>Recommend separate Discord Account dedicated to Midjourney</li>
            <li>Create Own Server on Discord</li>
            <li>Invite Midjourney Bot to Server</li>
            <li>Enable Developer options in Discord</li>
            <li>Copy Server ID</li>
            <li>Copy Channel ID</li>
            <li>Copy Discord User Token. Do not share lightly!</li>
            <li>Submit Credentials</li>
          </Typography>
        </Box>
        <Box sx={{ width: '600px', height: '300px', marginBottom: '30px'}}>
          <VideoPlayer videoFileName='videos/Tutorial_provideMidjCredentials.mp4'></VideoPlayer>
        </Box>
        <Box sx={{
          marginTop: '10px',
          marginBottom: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <TextField
            value={midjServerId}
            onChange={handleMidjServerIdChange}
            label="Enter ServerID here"
            variant="outlined"
            styles={{display: 'flex'}}
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            value={midjChannelId}
            onChange={handleMidjChannelIdChange}
            label="Enter channelID here"
            variant="outlined"
            styles={{display: 'flex'}}
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            value={midjUserToken}
            onChange={handleMidjUserTokenChange}
            label="Enter the User Token here"
            variant="outlined"
            styles={{display: 'flex'}}
            sx={{ marginBottom: '10px' }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitMidjAccess}
          disabled={loading || !midjSubmittable} // Disable the button while loading
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box>

      {/* Open AI */}
      {/* <Box sx={{
        marginBottom: '40px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' // Customize the shadow properties here
      }}>
        <Box sx={{
          marginBottom: '20px'
        }}>
          <Typography variant="h4" gutterBottom>
            OpenAI (GPT) API Key
          </Typography>
          <Typography variant="paragraph" gutterBottom>
            In order to create Artwork listings for your POD stores, OpenAIs GPT API service is being used.
          </Typography>
        </Box>
        <Box sx={{
          marginBottom: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <TextField
            value={openAIApiKey}
            onChange={handleOpenAIApiKeyChange}
            label="Enter Authorization Key"
            variant="outlined"
            styles={{display: 'flex'}}
            sx={{ marginBottom: '10px' }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitOpenAIApiKey}
          disabled={loading || !openAISubmittable} // Disable the button while loading
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box> */}

      
      {/* <Box sx={{
        marginBottom: '40px', //TODO
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' // Customize the shadow properties here
      }}>
        <Box sx={{
          marginBottom: '20px'
        }}>
          <Typography variant="h4" gutterBottom>
            Vectorizer API Key *optional
          </Typography>
          <Typography variant="paragraph" gutterBottom>
            In order to upscale the quality of cropped sticker images, PodPipe currently uses the amazing Vectorizer service. You can easily sign up and request API access, which is currently free of charge!
          </Typography>
          <Typography component="ol" sx={{
            marginTop: '10px',
          }}>
            <li>
              Head over to{' '}
              <Link href="https://vectorizer.ai/" target="_blank" rel="noopener">
                vectorizer.ai
              </Link>
              {' '} and sign up
            </li>
            <li>
              Request API access at{' '}
              <Link href="https://vectorizer.ai/api" target="_blank" rel="noopener">
                vectorizer.ai/api
              </Link>
            </li>
            <li>
              Go to your account information at{' '}
              <Link href="https://vectorizer.ai/account" target="_blank" rel="noopener">
                vectorizer.ai/account
              </Link>
              {' '} and in the API Key section copy the Authorization Key
            </li>
            <li>
              Paste it into the Textfield and submit
            </li>
          </Typography>
        </Box>
        <Box sx={{
          marginBottom: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <TextField
            value={vectorizerApiKey}
            onChange={handleVectorizerApiKeyChange}
            label="Enter Authorization Key"
            variant="outlined"
            styles={{display: 'flex'}}
            sx={{ marginBottom: '10px' }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitVectorizerApiKey}
          disabled={loading || !vectorizerSubmittable} // Disable the button while loading
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box> */}


      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default withAuthenticator(AccountPage);
