import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../StateContext'; // Assuming you have Auth state in your StateContext

const LoginPage = () => {
  const { isLoggedIn } = useContext(StateContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
}

export default withAuthenticator(LoginPage);
