import React, { useState, useContext, useEffect } from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { DataStore, SortDirection} from 'aws-amplify';
import { Theme } from '../models';

import { StateContext } from '../StateContext';
import { OptionsSelect } from '../components/OptionsSelect';
import { Typography, Button, TextField, Autocomplete, Box, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {createTheme, updateTheme, deleteTheme} from '../services/themeService';
import {fetchOptions} from '../services/optionsService';

import nicheOptionDescriptions from '../components/nicheOptionDescriptions';
import NicheDeleteDialog from '../components/niche/NicheDeleteDialog';

const NichesPage = () => {

  const infoTextMap = nicheOptionDescriptions;

  const { isLoggedIn, userId } = useContext(StateContext);

  const [isCreatable, setIsCreatable] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  //former dummy data
  const [niches, setNiches] = useState([]);
  const [midjParameterOptions, setMidjParameterOptions] = useState({});
  const [aestheticsStylesOptions, setAestheticsStylesOptions] = useState([]);
  const [aestheticsThemesOptions, setAestheticsThemesOptions] = useState([]);
  const [aestheticsLensesOptions, setAestheticsLensesOptions] = useState([]);
  const [aestheticsMoodsOptions, setAestheticsMoodsOptions] = useState([]);
  const [aestheticsPosesOptions, setAestheticsPosesOptions] = useState([]);
  const [aestheticsColoringsOptions, setAestheticsColoringsOptions] = useState([]);

  const [selectedNiche, setSelectedNiche] = useState(null);
  const [isNicheEditable, setIsNicheEditable] = useState(false);
  const [isCreatingNewNiche, setIsCreatingNewNiche] = useState(false);

  const [isShowingOptions, setIsShowingOptions] = useState(false);

  const [nicheName, setNicheName] = useState('');
  const [topics, setTopics] = useState([]);
  const [artTypes, setArtTypes] = useState([]);

  const artTypeOptions = ['STICKER', 'STANDARD'];
  const artTypeDefaultOptions = ['STANDARD'];

  const topicsDefaultOptions = ['A random and bizarre theme']

  // Aesthetic state vars
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedMoods, setSelectedMoods] = useState([]);
  const [selectedColorings, setSelectedColorings] = useState([]);
  const [selectedLenses, setSelectedLenses] = useState([]);
  const [selectedPoses, setSelectedPoses] = useState([]);

  // Parameter State Vars
  const [selectedPVersions, setSelectedPVersions] = useState([]);
  const [selectedPStylizes, setSelectedPStylizes] = useState([]);
  const [selectedPChaos, setSelectedPChaos] = useState([]);
  const [selectedPQuality, setSelectedPQuality] = useState([]);
  const [selectedPStop, setSelectedPStop] = useState([]);
  const [selectedPOriginalStyles, setSelectedPOriginalStyles] = useState([]);
  const [selectedPNijiStyles, setSelectedPNijiStyles] = useState([]);
  const [selectedPTilePct, setSelectedPTilePct] = useState(null);
  const [selectedPNos, setSelectedPNos] = useState([]);
  const [selectedPArs, setSelectedPArs] = useState([]);

  useEffect(() => {
    if (nicheName && topics?.length > 0 && artTypes?.length > 0) {
      !isCreatable && setIsCreatable(true);
    } else {
      isCreatable && setIsCreatable(false);
    }
  }, [nicheName, topics, artTypes]);

  useEffect(() => {

    const loadOptions = async () => {
      const fetchMidParameterOptions = await fetchOptions('midj_prompt_parameters');
      setMidjParameterOptions(fetchMidParameterOptions);
      console.log('midjParameterOptions v: ', midjParameterOptions);
      
      const fetchAestheticsStylesOptions = await fetchOptions('prompt_aesthetics_styles');
      setAestheticsStylesOptions(fetchAestheticsStylesOptions);

      const fetchAestheticsThemesOptions = await fetchOptions('prompt_aesthetics_themes');
      setAestheticsThemesOptions(fetchAestheticsThemesOptions);

      const fetchAestheticsLensesOptions = await fetchOptions('prompt_aesthetics_lenses');
      setAestheticsLensesOptions(fetchAestheticsLensesOptions);

      const fetchAestheticsMoodsOptions = await fetchOptions('prompt_aesthetics_moods');
      setAestheticsMoodsOptions(fetchAestheticsMoodsOptions);

      const fetchAestheticsPosesOptions1 = await fetchOptions('prompt_aesthetics_poses');
      const fetchAestheticsPosesOptions = {
        id: "6dc45d6a-cc58-4a5c-9bd9-7121f4fee099",
        createdAt: "2023-06-19T10:38:21.517Z",
        name: "prompt_aesthetics_poses",
        select_values: [
          "3/4-profile",
          "aerial",
          "behind-shot",
          "full-body-low-angle",
          "full-body-side-profile",
          "glamour",
          "over-the-shoulder",
          "side-profile",
          "45-degree-rule",
          "crossed-legs",
          "leaning-back",
          "leaning-against-wall",
          "leaning-forward",
          "s-shape",
          "crossed-arms",
          "hold-collar",
          "hands-on-hips",
          "hands-in-pockets",
          "looking-over-shoulder",
          "tilt-head"
        ],
        updatedAt: "2023-06-19T10:38:21.517Z",
        _lastChangedAt: "1687171101541",
        _version: "1",
        __typename: "Options"
      };
      console.log('fetchAestheticsPosesOptions1: ', fetchAestheticsPosesOptions1);
      console.log('fetchAestheticsPosesOptions: ', fetchAestheticsPosesOptions);
      setAestheticsPosesOptions(fetchAestheticsPosesOptions1);

      const fetchAestheticsColoringsOptions = await fetchOptions('prompt_aesthetics_colorings');
      setAestheticsColoringsOptions(fetchAestheticsColoringsOptions);
    };

    if (isLoggedIn && userId) {
      // loadThemes();
      loadOptions();
      // clearDataStore();
    }
  }, [isLoggedIn, userId]);

  useEffect(() => {
    let subscription;

    const subscribeToThemes = async () => {
      try {
        subscription = DataStore.observeQuery(
          Theme,
          t => t.userID.eq(userId), {
            sort: s => s.createdAt(SortDirection.ASCENDING)
          }
        ).subscribe(snapshot => {
          const { items, isSynced } = snapshot;
          console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
          items.forEach((item) => {
            console.log('item: ', item)
          }); 
          setNiches(items);
        });
      } catch (error) {
        console.error('Failed to fetch initial artworks:', error);
      }
    };

    if (isLoggedIn && userId) {
      subscribeToThemes();
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [isLoggedIn, userId]);

  const handleNicheNameChange = (event) => {
    setNicheName(event.target.value);
  };

  const handleNicheSelect = (event, value) => {
    setSelectedNiche(value);
    setIsShowingOptions(true);
    setIsCreatingNewNiche(false);
  
    // Set the state variables based on the selected niche object
    if (value) {
      setNicheName(value.name);
      setArtTypes(value.art_types || []);
      setTopics(value.topics || []);
      setSelectedStyles(value.aesthetics?.styles || []);
      setSelectedThemes(value.aesthetics?.themes || []);
      setSelectedMoods(value.aesthetics?.moods || []);
      setSelectedColorings(value.aesthetics?.colorings || []);
      setSelectedLenses(value.aesthetics?.lenses || []);
      setSelectedPoses(value.aesthetics?.poses || []);

      setSelectedPVersions(Object.keys(value.midj_parameters?.v || {}));
      setSelectedPStylizes(Object.keys(value.midj_parameters?.s || {}));
      setSelectedPChaos(Object.keys(value.midj_parameters?.c || {}));
      setSelectedPQuality(Object.keys(value.midj_parameters?.q || {}));
      setSelectedPStop(Object.keys(value.midj_parameters?.stop || {}));
      setSelectedPOriginalStyles(Object.keys(value.midj_parameters?.original_style || {}));
      setSelectedPNijiStyles(Object.keys(value.midj_parameters?.niji_style || {}));
      setSelectedPTilePct(value.midj_parameters?.tile || null);
      setSelectedPNos(value.midj_parameters?.no || []);
      setSelectedPArs(Object.keys(value.midj_parameters?.ar || {}));
    }
  };

  const handleEditNiche = () => {
    setIsNicheEditable(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteNiche = () => {
    console.log('delete Niche!');
    if (selectedNiche) {
      const deleteArtwork = deleteTheme(selectedNiche?.id);
      if (deleteArtwork) {
        resetStateVariables();
      }
    }
  };

  const validateAspectRatio = (aspectRatio) => {
    const regex = /^\d+(:\d*)?$/;
    return regex.test(aspectRatio);
  }

  const resetStateVariables = () => {
    setNicheName('');
    setArtTypes([]);
    setTopics([]);
    setSelectedStyles([]);
    setSelectedThemes([]);
    setSelectedMoods([]);
    setSelectedColorings([]);
    setSelectedLenses([]);
    setSelectedPoses([]);
    setSelectedPVersions([]);
    setSelectedPStylizes([]);
    setSelectedPChaos([]);
    setSelectedPQuality([]);
    setSelectedPStop([]);
    setSelectedPOriginalStyles([]);
    setSelectedPNijiStyles([]);
    setSelectedPTilePct(null);
    setSelectedPNos([]);
    setSelectedPArs([]);

    setSelectedNiche(null);
  };
  
  const handleNewNiche = () => {
    setSelectedNiche(null);
    setIsNicheEditable(true);
    setIsShowingOptions(true);
    setIsCreatingNewNiche(true);
  
    // Reset other state variables
    resetStateVariables();
  };

  const handleCreateOrUpdateNiche = () => {
    if (isCreatingNewNiche) {
      // Create new Niche object from the state variables
      const newNiche = {
        id: 'newId', // Generate a new ID for the new niche
        userID: userId,
        name: nicheName,
        art_types: artTypes.length > 0 ? [...artTypes] : [...artTypeDefaultOptions],
        topics: topics.length > 0 ? [...topics] : [...topicsDefaultOptions],
        // Other properties based on the state variables
        // ...
        aesthetics: {
          styles: [...selectedStyles],
          themes: [...selectedThemes],
          moods: [...selectedMoods],
          colorings: [...selectedColorings],
          lenses: [...selectedLenses],
          poses: [...selectedPoses],
        },
        // midj_parameters based on state variables
        midj_parameters: {
          v: { ...selectedPVersions.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          s: { ...selectedPStylizes.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          c: { ...selectedPChaos.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          q: { ...selectedPQuality.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          stop: { ...selectedPStop.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          original_style: { ...selectedPOriginalStyles.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          niji_style: { ...selectedPNijiStyles.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          tile: selectedPTilePct,
          no: [...selectedPNos],
          ar: { ...selectedPArs.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
        },
      };
      createTheme(newNiche);
    } else {
      // Apply the state variables to the selected niche object
      if (selectedNiche) {
        // const selectedNicheObj = niches.find((niche) => niche.name === nicheName);
        // const selectedNicheObj = {...selectedNiche};
        const updatedNiche = Theme.copyOf(selectedNiche, updated => {
          updated.name = nicheName;
          updated.art_types = artTypes.length > 0 ? artTypes : artTypeDefaultOptions;
          updated.topics = [...topics];
          updated.topics = topics.length > 0 ? [...topics] : [...topicsDefaultOptions];
          updated.aesthetics = {
            styles: [...selectedStyles],
            themes: [...selectedThemes],
            moods: [...selectedMoods],
            colorings: [...selectedColorings],
            lenses: [...selectedLenses],
            poses: [...selectedPoses],
          };
          // midj_parameters based on state variables
          updated.midj_parameters = {
            v: { ...selectedPVersions.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            s: { ...selectedPStylizes.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            c: { ...selectedPChaos.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            q: { ...selectedPQuality.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            stop: { ...selectedPStop.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            original_style: { ...selectedPOriginalStyles.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            niji_style: { ...selectedPNijiStyles.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
            tile: selectedPTilePct,
            no: [...selectedPNos],
            ar: { ...selectedPArs.reduce((obj, key) => ({ ...obj, [key]: 1 }), {}) },
          };
        });
        console.log('edited niche: ', updatedNiche);
        updateTheme(updatedNiche);
      }
    }
    resetStateVariables();
    setIsShowingOptions(false);
  };
  
  return (
    <Box sx={{ marginTop: '30px', marginBottom: '30px'}}>
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="h3">Niche Builder</Typography>
        <Typography variant="h6">Setup prompt templates to create images that reflect the Niches you want to go for! </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{alignItems: 'center', flex: '1 1 70%' }}>
          <Autocomplete
            options={niches}
            value={selectedNiche}
            onChange={handleNicheSelect}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Existing Niche"
                variant="outlined"
                autoComplete="off"
              />
            )}
            style={{marginBottom: '5px' }}
          />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditNiche}
              disabled={!selectedNiche} // Disable the Edit button when no niche is selected
            >
              Edit
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDeleteDialog}
              disabled={!selectedNiche} // Disable the Edit button when no niche is selected
            >
              Delete
            </Button>

            <Button
            variant="contained"
              color="primary"
              onClick={handleNewNiche}
            >
              New Niche
            </Button>
          </div>
        </div>
      </div>
      
      {isShowingOptions && (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              value={nicheName}
              onChange={handleNicheNameChange}
              label="Enter Niche Name"
              variant="outlined"
              styles={{display: 'flex'}}
            />
          </div>
          <OptionsSelect
            infoText={infoTextMap['artType']}
            label="Art Types" 
            options={artTypeOptions} 
            optionDisplayKey={null}
            defaultValue={artTypeDefaultOptions}
            value={artTypes}
            setValue={setArtTypes}
            isEditable={isNicheEditable}
            startWithDefault={isCreatingNewNiche}
          />
          <OptionsSelect
            infoText={infoTextMap['topic']}
            label="Topics" 
            options={['a random and bizarre topic']} 
            optionDisplayKey={null}
            defaultValue={artTypeDefaultOptions}
            value={topics}
            setValue={setTopics}
            isEditable={isNicheEditable}
            startWithDefault={false}
            allowFreeSolo={true}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1b-header"
            >
              <Typography  style={{ fontWeight: 'bold' }}>Advanced Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <OptionsSelect
                infoText={infoTextMap['aestheticStyle']}
                label="Select Styles" 
                options={aestheticsStylesOptions.select_values} 
                optionDisplayKey={null}
                defaultValue={null}
                value={selectedStyles}
                setValue={setSelectedStyles}
                isEditable={isNicheEditable}
                startWithDefault={false}
              />
              <OptionsSelect
                infoText={infoTextMap['aestheticTheme']}
                label="Select Themes" 
                options={aestheticsThemesOptions.select_values} 
                optionDisplayKey={null}
                defaultValue={null}
                value={selectedThemes}
                setValue={setSelectedThemes}
                isEditable={isNicheEditable}
                startWithDefault={false}
              />
              <OptionsSelect
                infoText={infoTextMap['aestheticMood']}
                label="Select Moods" 
                options={aestheticsMoodsOptions.select_values} 
                optionDisplayKey={null}
                defaultValue={null}
                value={selectedMoods}
                setValue={setSelectedMoods}
                isEditable={isNicheEditable}
                startWithDefault={false}
              />
              <h2>Midjourney Parameters</h2>
              <h3>Prompt Parameters</h3>
              <OptionsSelect
                infoText={infoTextMap['midjourneyVersion']}
                label="Versions" 
                options={midjParameterOptions.parameter_options?.all?.v || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.v || []}
                value={selectedPVersions}
                setValue={setSelectedPVersions}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyStylize']}
                label="Stylize Value" 
                options={midjParameterOptions.parameter_options?.all?.s || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.s || []}
                value={selectedPStylizes}
                setValue={setSelectedPStylizes}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyChaos']}
                label="Chaos Value" 
                options={midjParameterOptions.parameter_options?.all?.c || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.c || []}
                value={selectedPChaos}
                setValue={setSelectedPChaos}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyQuality']}
                label="Quality" 
                options={midjParameterOptions.parameter_options?.all?.q || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.q || []}
                value={selectedPQuality}
                setValue={setSelectedPQuality}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyStop']}
                label="Stop Stage" 
                options={midjParameterOptions.parameter_options?.all?.stop || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.stop || []}
                value={selectedPStop}
                setValue={setSelectedPStop}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyStyle']}
                label="Styles for Versions 4 - 5.1" 
                options={midjParameterOptions.parameter_options?.all?.original_style || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.original_style || []}
                value={selectedPOriginalStyles}
                setValue={setSelectedPOriginalStyles}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyStyleNiji']}
                label="Styles for Version Niji" 
                options={midjParameterOptions.parameter_options?.all?.niji_style || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.niji_style || []}
                value={selectedPNijiStyles}
                setValue={setSelectedPNijiStyles}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyTile']}
                label="Tile Occurance (Seamless Pattern)" 
                options={midjParameterOptions.parameter_options?.all?.tile || ''} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.tile[0] || ''}
                value={selectedPTilePct}
                setValue={setSelectedPTilePct}
                isEditable={isNicheEditable}
                startWithDefault={isCreatingNewNiche}
                allowMultiple={false}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyAspectRatio']}
                label="Aspect Ratios" 
                options={midjParameterOptions.parameter_options?.all?.ar || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.ar || []}
                value={selectedPArs}
                setValue={setSelectedPArs}
                isEditable={isNicheEditable}
                startWithDefault={false}
                allowFreeSolo={true}
                isValidInputFunc={validateAspectRatio}
              />
              <OptionsSelect
                infoText={infoTextMap['midjourneyNo']}
                label="Concepts to Avoid in Themes" 
                options={midjParameterOptions.parameter_options?.all?.no || []} 
                optionDisplayKey={null}
                defaultValue={midjParameterOptions.parameter_options?.default?.no || []}
                value={selectedPNos}
                setValue={setSelectedPNos}
                isEditable={isNicheEditable}
                startWithDefault={false}
                allowFreeSolo={true}
              />
            </AccordionDetails>
          </Accordion>
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', }}>
            <Button variant="contained" onClick={handleCreateOrUpdateNiche} disabled={!isCreatable}>
              {isCreatingNewNiche ? 'Create' : 'Update'}
            </Button>
          </div>
        </div>
      )}
      < NicheDeleteDialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog} onDelete={handleDeleteNiche}/>
    </Box>
  );
};

export default withAuthenticator(NichesPage);
