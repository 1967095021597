import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

const ExportDialog = ({ open, artworkStage, onClose, handleExport}) => {
  const [isUpscaledStage, setIsUpscaledStage] = useState(artworkStage=='UPSCALED');

  const [checkedOriginal, setCheckedOriginal] = useState(isUpscaledStage ? false : true);
  const [checkedProcessed, setCheckedProcessed] = useState(isUpscaledStage ? false : false);
  const [checkedUpscaled, setCheckedUpscaled] = useState(isUpscaledStage ? true : false);
  const [excelFileNameOptions, setExcelFileNameOptions] = useState(isUpscaledStage ? ['UPSCALED'] : ['ORIGINAL']);
  const [selectedExcelFileName, setSelectedExcelFileName] = useState(isUpscaledStage ? 'UPSCALED' : 'ORIGINAL');

  const [isDoneDisabled, setIsDoneDisabled] = useState(true);

  useEffect(() => {
    const tmpIsUpscaledStage = artworkStage==='UPSCALED';
    setIsUpscaledStage(tmpIsUpscaledStage);
    if (tmpIsUpscaledStage) {
      if (!checkedUpscaled) {
        setCheckedUpscaled(true);
        setCheckedOriginal(false);
        setCheckedProcessed(false);
        setExcelFileNameOptions(['UPSCALED']);
        setSelectedExcelFileName('UPSCALED');
      }
      !checkedUpscaled && setCheckedUpscaled(true);
    } else {
      if (checkedUpscaled) {
        setCheckedUpscaled(false);
        setCheckedOriginal(true);
        setCheckedProcessed(false);
        setExcelFileNameOptions(['ORIGINAL']);
        setSelectedExcelFileName('ORIGINAL');
      }
    }
    // console.log('adapted options: ', )
  }, [artworkStage]);

  useEffect(() => {
    setIsDoneDisabled(
      !(checkedOriginal || checkedProcessed || checkedUpscaled) ||
      selectedExcelFileName.length === 0
    );
  }, [checkedOriginal, checkedProcessed, checkedUpscaled, selectedExcelFileName]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'checkedOriginal') {
      setCheckedOriginal(checked);
      if (checked) {
        !excelFileNameOptions.includes('ORIGINAL') && setExcelFileNameOptions((prevOptions) => [...prevOptions, 'ORIGINAL']);
      } else {
        setExcelFileNameOptions((prevOptions) => prevOptions.filter((option) => option !== 'ORIGINAL'));
      }
    } else if (name === 'checkedProcessed') {
      setCheckedProcessed(checked);
      if (checked) {
        !excelFileNameOptions.includes('PROCESSED') && setExcelFileNameOptions((prevOptions) => [...prevOptions, 'PROCESSED']);
      } else {
        setExcelFileNameOptions((prevOptions) => prevOptions.filter((option) => option !== 'PROCESSED'));
      }
    } else if (name === 'checkedUpscaled') {
      setCheckedUpscaled(checked);
      if (checked) {
        !excelFileNameOptions.includes('UPSCALED') && setExcelFileNameOptions((prevOptions) => [...prevOptions, 'UPSCALED']);
      } else {
        setExcelFileNameOptions((prevOptions) => prevOptions.filter((option) => option !== 'UPSCALED'));
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Export Options</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Checkbox
            color="primary"
            checked={checkedOriginal}
            onChange={handleCheckboxChange}
            name="checkedOriginal"
          />
          <Typography>Export Original</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox
            color="primary"
            checked={checkedProcessed}
            onChange={handleCheckboxChange}
            name="checkedProcessed"
          />
          <Typography>Export Processed</Typography>
        </Box>
        {isUpscaledStage && (
          <Box display="flex" alignItems="center">
            <Checkbox
              color="primary"
              checked={checkedUpscaled}
              onChange={handleCheckboxChange}
              name="checkedUpscaled"
            />
            <Typography>Export Upscaled</Typography>
          </Box>
        )}
        <Box mt={2}>
          <FormControl fullWidth>
            <InputLabel id="excel-file-name-select-label">Which for Excel file?</InputLabel>
            <Select
              labelId="excel-file-name-select-label"
              id="excel-file-name-select"
              value={selectedExcelFileName}
              onChange={(e) => setSelectedExcelFileName(e.target.value)}
            >
              {excelFileNameOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!isUpscaledStage && (
          <Typography variant="h6">*for high resolution upscale first!</Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
        <IconButton onClick={() => {
            handleExport(checkedOriginal, checkedProcessed, checkedUpscaled, selectedExcelFileName)
            onClose();
          }
        } disabled={isDoneDisabled}>
          <DoneIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
