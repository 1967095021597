/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://7vd5neefcnc5djmarbh6a6y3jy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ybu3gw5aendublzjndqr73xnee",
    "aws_cognito_identity_pool_id": "eu-central-1:7956e698-9b3c-42ae-ad07-9b3a69e9ea27",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_GCW0tsGga",
    "aws_user_pools_web_client_id": "19ck24lbooauinl1piv4015mdj",
    "oauth": {
        "domain": "podpipebe0cb0ba-be0cb0ba-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "https://www.tobias-dellert.com/",
        "redirectSignOut": "https://www.tobias-dellert.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pod-pipe-storage-bucket224731-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
