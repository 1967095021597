import React, { useEffect, useContext, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { StateContext } from './StateContext';

import { styled } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import {
  Toolbar,
  useMediaQuery
} from '@mui/material';

import { StateProvider } from './StateContext';
import {
  HomePage,
  LoginPage,
  AccountPage,
  CommunityPage,
  GuidePage,
  CreateArtworksPage,
  NichesPage,
  PaymentPage,
  GalleryPage,
  LoginPage2,
  TutorialsPage,
  FAQPage
} from './pages';
import Header from './components/Header';
import SideNavBar from './components/SideNavBar';

const drawerWidth = 240;
const transitionDuration = 0;//1000

const Root = styled('div')(() => ({
  display: 'flex',
}));

const StyledContent = styled('main')(({ theme, open, headerHeight }) => ({
  flex: 1.0, // Fill the available space
  padding: `${theme.spacing(10)}px ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px`,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: transitionDuration,
  }),
  marginLeft: open ? theme.drawerWidth  : 0,
  marginLeft: open ? `calc(${theme.drawerWidth}px - ${50}px)`  : 0,
  marginRight: 15,
  // marginTop: 15,
  // Set a fixed height for the content area
  // height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px + 60px)`,
  height: `calc(100vh - ${0}px)`,
  // overflow: 'scroll', // Enable scrolling, TODO: related to bad Safari look
  overflowY: 'scroll',
  overflowX: 'hidden',
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  flexDirection: 'column',
}));

// const HeaderPlaceholder = styled('div')(({ theme }) => ({
//   height: '64px', // Set the height of the placeholder to match the fixed header (64px)
// }));


const App = () => {
  const theme = useTheme();
  const greaterThan375 = useMediaQuery("(min-width:450px)");//376
  // theme.drawerWidth = greaterThan375 ? drawerWidth : '100%';
  const { open, setOpen } = useContext(StateContext); // Access the open state variable from StateContext
  theme.drawerWidth = drawerWidth;
  const headerRef = useRef(null);
  const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;

  useEffect(() => {
    setOpen(greaterThan375);
  }, [greaterThan375]);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  return (
    <Router>
      <Root>
        <Header ref={headerRef} handleMenuClick={handleMenuClick} />
        {/* <HeaderPlaceholder /> Add the HeaderPlaceholder component */}
        <SideNavBar />
        <StyledContent open={open} headerHeight={headerHeight}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/tutorials" element={<TutorialsPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/create" element={<CreateArtworksPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/niches" element={<NichesPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/guide" element={<GuidePage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/tokens" element={<PaymentPage />} />
          </Routes>
        </StyledContent>
      </Root>
    </Router>
  );
};

export default App;