import React, { useContext } from 'react';
import { StateContext } from '../../StateContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import StyledButton from '../StyledButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const exps = [
  {
    label: 'Artworks per Month',
    value: '5K+',
  },
  {
    label: 'Generated Artworks and Listings',
    value: 'Auto',
  },
  {
    label: 'Cropping and Upscaling',
    value: 'Easy',
  }
];

const ExpItem = ({ item }) => {
  const { value, label } = item;
  return (
    <Box sx={{ textAlign: 'center', mb: { xs: 1, md: 0 } }}>
      <Typography
        sx={{ color: 'primary.main', mb: { xs: 1, md: 2 }, fontSize: { xs: 34, md: 44 }, fontWeight: 'bold' }}
      >
        {value}
      </Typography>
      <Typography color="text.secondary" variant="h5">
        {label}
      </Typography>
    </Box>
  );
};

const HomeHero = (handleGetStartedClick) => {
    const { 
      setHeaderLoginDialogOpen
    } = useContext(StateContext);

    const handleGetStarted = () => {
      console.log('set HeaderLoginDialog open');
      setHeaderLoginDialogOpen(true);
    };

    return (
      <Box id="hero" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
        <Container maxWidth="lg">
          <Grid container spacing={0} sx={{ flexDirection: { xs: 'column', md: 'unset' } }}>
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography
                    component="h2"
                    sx={{
                      position: 'relative',
                      fontSize: { xs: 40, md: 72 },
                      letterSpacing: 1.5,
                      fontWeight: 'bold',
                      lineHeight: 1.3,
                    }}
                  >
                    <Typography
                      component="mark"
                      sx={{
                        position: 'relative',
                        color: 'primary.main',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        backgroundColor: 'unset',
                      }}
                    >
                      Boost{' '}
                      {/* <Box
                        sx={{
                          position: 'absolute',
                          top: { xs: 24, md: 34 },
                          left: 2,
                          transform: 'rotate(3deg)',
                          '& img': { width: { xs: 146, md: 210 }, height: 'auto' },
                        }}
                      >
                        <img src="/images/headline-curve.svg" alt="Headline curve" />
                      </Box> */}
                    </Typography>
                    your{' '}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        position: 'relative',
                        '& svg': {
                          position: 'absolute',
                          top: -16,
                          right: -21,
                          width: { xs: 22, md: 30 },
                          height: 'auto',
                        },
                      }}
                    >
                      PoD
                      <svg version="1.1" viewBox="0 0 3183 3072">
                        <g id="Layer_x0020_1">
                          <path
                            fill="#D0392F"
                            d="M2600 224c0,0 0,0 0,0 236,198 259,562 52,809 -254,303 -1849,2089 -2221,1776 -301,-190 917,-1964 1363,-2496 207,-247 570,-287 806,-89z"
                          />
                          <path
                            fill="#D0392F"
                            d="M3166 2190c0,0 0,0 0,0 64,210 -58,443 -270,516 -260,90 -1848,585 -1948,252 -104,-230 1262,-860 1718,-1018 212,-73 437,39 500,250z"
                          />
                          <path
                            fill="#D0392F"
                            d="M566 3c0,0 0,0 0,0 -219,-26 -427,134 -462,356 -44,271 -255,1921 90,1962 245,62 628,-1392 704,-1869 36,-221 -114,-424 -332,-449z"
                          />
                        </g>
                      </svg>
                    </Typography>{' '}
                    <br />
                    to the next Level
                  </Typography>
                </Box>
                <Box sx={{ mb: 4, width: { xs: '100%', md: '70%' } }}>
                  {/* <Typography sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
                    {
                      "Automate the tedious parts of your workflow! You need Artwork Generation, Sticker Cropping, Upscaling, Listings and Storage? You want to generate a few hundred PrintOnDemand products ready for upload today? You have come to the right place!"
                    }
                  </Typography> */}
                  <Typography sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
                    Automate the tedious parts of your workflow! You need Artwork Generation, Listings, Sticker Cropping, Upscaling and Storage? You want to generate a few hundred <strong>PrintOnDemand</strong> products ready for upload today? You have come to the right place!
                  </Typography>

                </Box>
                <Box sx={{ '& button': { mr: 2 } }}>
                  {/* <ScrollLink to="exampleArtworks" spy={true} smooth={true} offset={0} duration={350}>
                    <StyledButton color="primary" size="large" variant="contained">
                      Get Started
                    </StyledButton>
                  </ScrollLink> */}
                  <StyledButton 
                    color="primary" 
                    size="large" 
                    variant="contained"
                    onClick={handleGetStarted}>
                    Get Started
                  </StyledButton>
                  {/* <ScrollLink to="exampleArtworks" spy={true} smooth={true} offset={0} duration={350}>
                    <StyledButton color="primary" size="large" variant="outlined" startIcon={<PlayArrowIcon />}>
                      Watch Video
                    </StyledButton>
                  </ScrollLink> */}
                  <NavLink to="/tutorials">
                    <StyledButton color="primary" size="large" variant="outlined" startIcon={<PlayArrowIcon />}>
                      Watch Video
                    </StyledButton>
                  </NavLink>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
              {/* Sertificate badge */}
              {/* <Box
                sx={{
                  position: 'absolute',
                  bottom: 30,
                  // left: { xs: 0, md: -150 },
                  boxShadow: 1,
                  borderRadius: 3,
                  px: 2,
                  py: 1.4,
                  zIndex: 1,
                  backgroundColor: 'background.paper',
                  display: 'flex',
                  alignItems: 'flex-start',
                  // width: 280,
                }}
              >
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: '50%',
                    width: 44,
                    height: 44,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2,
                    '& img': { width: '32px !important', height: 'auto' },
                  }}
                >
                  <img src="/assets/images/certificate.png" alt="Certificate icon" style={{ width: 50, height: 50 }} />
                </Box>
                <Box>
                  <Typography
                    component="h6"
                    sx={{ color: 'secondary.main', fontSize: '1.1rem', fontWeight: 700, mb: 0.5 }}
                  >
                    Certificate
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary', lineHeight: 1.3 }}>
                    There are certificates for all courses.
                  </Typography>
                </Box>
              </Box> */}
              {/* <Box sx={{ lineHeight: 0 }}>
                <img src="/assets/images/wonderland_red_queen.png" alt="Hero img" style={{ width: 775, height: 787 }} />
              </Box> */}
              <Box sx={{ lineHeight: 0, width: '100%', maxWidth: 775 }}>
                <img src="/assets/images/alice/ORIGINAL_Alice in Wonderland - Enchanted Whimsy_small.png" alt="Hero img" style={{ width: '100%', height: 'auto' }} />
            </Box>

            </Grid>
          </Grid>
  
          {/* Experience */}
          <Box sx={{ boxShadow: 2, py: 4, px: 7, borderRadius: 4 }}>
            <Grid container spacing={2}>
              {exps.map((item) => (
                <Grid key={item.value} item xs={12} md={4}>
                  <ExpItem item={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    )
  }

export default HomeHero
